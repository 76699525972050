import React, { createContext, useState } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";

export const BillingTypeContext = createContext();

export const BillingTypeContextProvider = ({ children }) => {
  const [billingtypeList, setBillingTypeList] = useState([]);

  const getBillingTypeList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/billing_types`,
        BEARER_TOKEN_HEADERS
      );
      setBillingTypeList(res?.data);
    } catch (err) {
      errorToast(err);
    }
  };

  return (
    <BillingTypeContext.Provider
      value={{
        getBillingTypeList,
        billingtypeList,
      }}
    >
      {children}
    </BillingTypeContext.Provider>
  );
};
