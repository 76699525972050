import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { StatusContext } from "../../../context/StatusContext";
import { ProductTypeContext } from "../../../context/ProductTypeContext";
import TextArea from "antd/lib/input/TextArea";

const CreateProductModal = ({ handleClose, addProduct }) => {
  const { getStatusList, statusList } = useContext(StatusContext);
  const { getProductTypeList, productTypeList } =
    useContext(ProductTypeContext);
  const [form] = Form.useForm();

  useEffect(() => {
    getStatusList("?type=product");
    getProductTypeList();
  }, []);

  const handleFormSubmit = () => {
    const data = form.getFieldsValue();

    addProduct(data);
    handleClose();
  };

  return (
    <Modal
      visible={true}
      title={<span>Create Product</span>}
      centered
      onCancel={handleClose}
      footer={null}
      width={750}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <label className="title">
                  Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please Input your Product Name!",
                    },
                  ]}
                >
                  <Input name="name" type="text" placeholder="Name..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Cost<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="cost"
                  rules={[
                    {
                      required: true,
                      message: "Please Input your Product Cost!",
                    },
                  ]}
                >
                  <Input name="cost" type="number" placeholder="Cost..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Status <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"status_uuid"}
                  rules={[
                    { required: true, message: "Please select your Status!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="status_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {statusList?.map((status, index) => (
                      <Option value={status.uuid} key={index}>
                        {status.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Product Type <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"product_type_uuid"}
                  rules={[
                    {
                      required: true,
                      message: "Please select your Product Type!",
                    },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="product_type_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {productTypeList?.map((productType, index) => (
                      <Option value={productType.uuid} key={index}>
                        {productType.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <label className="title">
                  Description<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please Input your Product Description!",
                    },
                  ]}
                >
                  <TextArea
                    name="description"
                    type="text"
                    placeholder="Description..."
                    rows={4}
                  ></TextArea>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Submit
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateProductModal;
