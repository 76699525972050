import React, { Children, createContext, useState } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";

export const ProjectContext = createContext();

export const ProjectContextProvider = ({ children }) => {
  const [projectList, setProjectList] = useState([]);
  const [clientProjectList, setClientProjectList] = useState([]);
  const [allProjectList, setAllProjectList] = useState([]);
  const [singleProjectLoader, setSingleProjectLoader] = useState(false);
  const [singleProject, setSingleProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const getProjectList = async (
    page,
    paginationStatus,
    clientQuery = "",
    companyQuery = ""
  ) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}${companyQuery}/projects?page=${page}&paginate=${paginationStatus}${clientQuery}`,
        BEARER_TOKEN_HEADERS
      );
      setProjectList(res?.data);
      console.log("This is My Data is Project", res?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getAllProjectList = async (clientQuery = "", companyQuery = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}${companyQuery}/projects${clientQuery}`,
        BEARER_TOKEN_HEADERS
      );
      setAllProjectList(res?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const registerProject = async (
    uuid,
    clientQuery = "",
    companyQuery = "",
    data,
    page,
    paginationStatus
  ) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/projects`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message ? res.data.message : "Project added successfully"
      );
      getProjectList(page, paginationStatus, clientQuery, companyQuery);
      if (uuid) {
        getClientProjectList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const deleteProject = async (
    uuid,
    clientQuery = "",
    companyQuery = "",
    id,
    page,
    paginationStatus
  ) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/projects/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getProjectList(page, paginationStatus, clientQuery, companyQuery);
      if (uuid) {
        getClientProjectList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getSingleProject = async (id) => {
    setSingleProjectLoader(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/projects/${id}`,
        BEARER_TOKEN_HEADERS
      );
      setSingleProject(res?.data);
      console.log("Project list result", res?.data);
      setSingleProjectLoader(false);
    } catch (err) {
      errorToast(err);
      setSingleProjectLoader(false);
    }
  };

  const updateProject = async (
    uuid,
    clientQuery = "",
    companyQuery = "",
    data,
    id,
    page,
    paginationStatus
  ) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/projects/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      toast.success(
        res?.data?.message ? res.data.message : "Project Updated successfully"
      );
      getProjectList(page, paginationStatus, clientQuery, companyQuery);
      if (uuid) {
        getClientProjectList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setLoading(false);
    }
  };

  const getClientProjectList = async (uuid, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/projects?page=${page}&paginate=${paginationStatus}&client_uuid=${uuid}`,
        BEARER_TOKEN_HEADERS
      );
      setClientProjectList(res?.data);
      console.log("This is My Data is Project", res?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        getSingleProject,
        getProjectList,
        registerProject,
        deleteProject,
        updateProject,
        getAllProjectList,
        getClientProjectList,
        clientProjectList,
        allProjectList,
        projectList,
        loading,
        data: singleProject,
        singleProjectLoader,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
