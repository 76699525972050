import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { GenericContext } from "../../../context/genericContext";
import ImageUploader from "../../imageUploader";
import { min } from "moment";
import { RolesContext } from "../../../context/RolesContext";

const UpdateUserModal = ({ handleClose, selectedData, updateData }) => {
  const { rolesList, getRolesList } = useContext(RolesContext);
  const [photo, setPhoto] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    getRolesList();
  }, []);

  useEffect(() => {
    console.log(rolesList);
  }, [rolesList]);

  useEffect(() => {
    console.log("Selected Client", selectedData);
    form.setFieldsValue({
      firstname: selectedData.firstname,
      lastname: selectedData.lastname,
      email: selectedData.email,
      role_uuid: selectedData.role_uuid,
      is_active: selectedData.is_active,
    });
  }, []);

  const handleFormSubmit = () => {
    const data = form.getFieldsValue();
    let tempData = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      password: data.password,
      role_uuid: data.role_uuid,
      is_active: data.is_active ? data.is_active : false,
    };
    updateData(tempData, selectedData.uuid);
    handleClose();
  };
  return (
    <Modal
      visible={true}
      title={<span>Update User</span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <label className="title">
                  First Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input name="firstname" placeholder="First name..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Last Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"lastname"}
                  rules={[
                    { required: true, message: "Please input your last name!" },
                  ]}
                >
                  <Input name="lastname" placeholder="Last name..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"email"}
                  rules={[
                    { type: "email", message: "Enter a valid email" },
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input name="email" placeholder="Email..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Password<span style={{ color: "red" }}></span>
                </label>
                <Form.Item
                  name={"password"}
                  rules={[
                    { min: 6, message: "Password must be 6 character long" },
                  ]}
                >
                  <Input.Password
                    name="password"
                    autoComplete="new-password"
                    placeholder="Password..."
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={"12"}>
              <Col xs={24} sm={24}>
                <label className="title">
                  Role id <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item
                  name={"role_uuid"}
                  rules={[
                    { required: true, message: "Please select your role id!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="role_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {rolesList?.map((role, index) => (
                      <Option value={role.uuid} key={index}>
                        {role.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <Form.Item name="is_active" valuePropName="checked">
                  <Checkbox checked={true}>Is active?</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Update
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UpdateUserModal;
