import React, { useContext } from "react";
import signInBg from "../../../assets/backgrounds/singin-bg.svg";
import "./styles.scss";
import { ArrowRightOutlined } from "@ant-design/icons";
import Logobox from "../../../components/logoBox";
import { Row, Col, Form, Input, Button } from "antd";
import { AuthContext } from "../../../context/AuthContext";
import DataLoader from "../../../components/dataLoader";
import { Redirect } from "react-router-dom";

const SignIn = () => {
  const [form] = Form.useForm();
  const { adminLogin, loginLoader , isLoggedIn } = useContext(AuthContext);
  const token = localStorage.getItem("crm_token");
  const role = localStorage.getItem("crm_role");
  const user = localStorage.getItem("crm_user_uuid");
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    credentials["token"] =
      "cflatDnTToOqsI-FFj1ro6:APA91bHZCNfgjR1bI1wlySybeHvoklgYcblb9hFtq3-axyD1OkobBkyEMB_7UawaSvU-ZZXQtsseP4hVu3Y0N_ncs_rwrSz4Zg7Yo8yMFesGCOuO1FU1D_bmJFW8vYmKOcYNlFK83uvv";
    credentials["platform"] = "web";
    adminLogin(credentials);
  };

  if (token && role && user) {
    return <Redirect to={"admin/dashboard"} />;
  }
  if (loginLoader) {
    return <DataLoader />;
  } else {
    return (
      <Row className="signin-wrapper">
        <Col sm={24} lg={12}>
          <div className="thumb-box">
            <Logobox size="lg" SignInLogo="SignInLogo" />
            <img src={signInBg} />
          </div>
        </Col>
        <Col sm={24} lg={12}>
          <div className="form-wrapper">
            <h3 className="title">LogIn</h3>
            <Form form={form} className="form" onFinish={handleFormFinish}>
              <label>Email:</label>
              <Form.Item
                name={"email"}
                rules={[
                  {
                    required: true,
                    message: "Email can't be empty",
                  },
                  {
                    type: "email",
                    message: "Invalid email",
                  },
                ]}
              >
                <Input name="email" placeholder="abxy@gmail.com" />
              </Form.Item>
              <label>Password:</label>
              <Form.Item
                name={"password"}
                rules={[
                  {
                    required: true,
                    message: "Password can't be empty",
                  },
                ]}
              >
                <Input.Password
                  className="pass"
                  name="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit">Login</Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    );
  }
};

export default SignIn;
