import React, { useEffect, useState, useContext, useCallback } from "react";
import { Card, Typography, Col, Row, Radio, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import DataLoader from "../../components/dataLoader";
import { getFormattedDateTime, getFormattedData } from "../../utils";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  CreateProjectModal,
  UpdateProjectModal,
  ConfirmationModal,
} from "../../components/Modals";
import { ProjectContext } from "../../context/ProjectContext";
const { Title } = Typography;

function ProjectManagement() {
  const [createProject, setCreateProjectModal] = useState(false);
  const {
    getProjectList,
    projectList,
    loading,
    registerProject,
    deleteProject,
    updateProject,
  } = useContext(ProjectContext);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [page, setPage] = useState(1);
  const [clientRole, setClientRole] = useState(null);
  const [clientUuid] = useState(localStorage.getItem("crm_user_uuid"));
  const [companyUuid] = useState(localStorage.getItem("crm_company_uuid"));
  const [clientQuery, setClientQuery] = useState("");
  const [companyQuery, setCompanyQuery] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const { meta, data } = projectList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };

  useEffect(() => {
    setClientRole(localStorage.getItem("crm_role"));
  }, []);

  useEffect(() => {
    let clientQuery = "";
    let companyQuery = "";
    if (clientUuid) {
      clientQuery = `&client_uuid=${clientUuid}`;
      setClientQuery(clientQuery);
    }
    if (companyUuid) {
      companyQuery = `/companies/${companyUuid}`;
      setCompanyQuery(companyQuery);
    }
    getProjectList(page, true, clientQuery, companyQuery);
  }, [page, clientUuid, companyUuid]);

  const handleCloseCreateModal = () => {
    setCreateProjectModal(false);
  };

  const handleCreateProject = (data) => {
    registerProject("", clientQuery, companyQuery, data, page, true);
  };

  const handleCreateModal = () => {
    setCreateProjectModal(true);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };

  const handleDeleteData = (id) => {
    deleteProject("", clientQuery, companyQuery, id, page, true);
  };

  const handleUpdateModal = (data) => {
    setselectedData(data);
    setShowUpdateModal(true);
  };

  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };

  const handleUpdateData = (data, id) => {
    updateProject("", clientQuery, companyQuery, data, id, page, true);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (data, value) => {
        return <span>{value?.title}</span>;
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (data, value) => {
        return <span>{value?.priority?.name}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data, value) => {
        return <span>{value?.status?.name}</span>;
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (data, value) => {
        return <span>{getFormattedDateTime(value?.start_date)}</span>;
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (data, value) => {
        return <span>{getFormattedDateTime(value?.end_date)}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        const { slug, uuid } = record;
        return (
          <div className="actions">
            <Link to={`project/${uuid}`} className="preview">
              <Tooltip title="View Project">
                <FontAwesomeIcon icon={faEye} />
              </Tooltip>
            </Link>
            {clientRole == "05857c29-5129-4aed-ae5e-30f0155fdbf3" ||
            clientRole == "33e2bba8-73ef-44f0-a6ab-60ff5146b74f" ? (
              <>
                <span
                  className="update"
                  onClick={() => handleUpdateModal(record)}
                >
                  <Tooltip title="Update Project">
                    <EditOutlined />
                  </Tooltip>
                </span>
                <span>
                  <Tooltip title="Delete Project">
                    <DeleteOutlined
                      onClick={() => handleConfirmModal(record)}
                    />
                  </Tooltip>
                </span>
              </>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            {clientRole == "05857c29-5129-4aed-ae5e-30f0155fdbf3" ||
            clientRole == "33e2bba8-73ef-44f0-a6ab-60ff5146b74f" ? (
              <>
                <div className="add-btn">
                  <button onClick={handleCreateModal}>Create Project</button>
                </div>
              </>
            ) : (
              <div className="mb-24"></div>
            )}
            <AdminDataTable
              data={data}
              columns={columns}
              pagination={{ ...paginationConfig, onChange: handlePageChange }}
            />
          </Col>
        </Row>
      </div>

      {createProject && (
        <CreateProjectModal
          handleClose={handleCloseCreateModal}
          addProject={handleCreateProject}
        />
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Project?"}
          description={"Are you sure you want to delete this Project?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}

      {showUpdateModal && (
        <UpdateProjectModal
          handleClose={handleUpdateModalClose}
          selectedData={selectedData}
          updateData={handleUpdateData}
        />
      )}
      {loading && <DataLoader />}
    </>
  );
}

export default ProjectManagement;
