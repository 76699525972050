import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Form, Input, Button, Tooltip } from "antd";
import { FilterFilled, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import DataLoader from "../../components/dataLoader";
import { getFormattedData } from "../../utils";
import FilterDrawer from "../../components/filterDrawer";
import {
  CreateTaskModal,
  UpdateTaskModal,
  ConfirmationModal,
} from "../../components/Modals";
import { TaskContext } from "../../context/TaskContext";
const { Title } = Typography;

function TaskManagement() {
  const [createTaskModal, setCreateTaskModal] = useState(false);
  const {
    registerTask,
    getTaskList,
    loading,
    taskList,
    deleteTask,
    updateTask,
  } = useContext(TaskContext);
  const [page, setPage] = useState(1);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [clientUuid] = useState(localStorage.getItem("crm_user_uuid"));
  const [companyUuid] = useState(localStorage.getItem("crm_company_uuid"));
  const [companyQuery, setCompanyQuery] = useState("");
  const [clientRole, setClientRole] = useState(null);
  const { meta, data } = taskList != "" ? taskList : "";
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };
  useEffect(() => {
    let clientQuery = "";
    if (clientUuid) {
      clientQuery = `/clients/${clientUuid}`;
    }
    if (companyUuid) {
      clientQuery = `/companies/${companyUuid}`;
      setCompanyQuery(clientQuery);
    }
    getTaskList(page, true, clientQuery);
  }, [page, clientUuid, companyUuid]);

  useEffect(() => {
    setClientRole(localStorage.getItem("crm_role"));
  }, []);

  const handleCreateModal = () => {
    setCreateTaskModal(true);
  };

  const handleCloseCreateModal = () => {
    setCreateTaskModal(false);
  };

  const handleCreateTask = (data) => {
    registerTask("", companyQuery, data, page, true);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };

  const handleDeleteData = (id) => {
    deleteTask("", companyQuery, id, page, true);
  };

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleUpdateModal = (data) => {
    setselectedData(data);
    setShowUpdateModal(true);
  };

  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };

  const handleUpdateData = (data, id) => {
    updateTask("", companyQuery, data, id, page, true);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (data, value) => {
        return <span>{value?.title}</span>;
      },
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      render: (data, value) => {
        return <span>{value?.project?.title}</span>;
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (data, value) => {
        return <span>{value?.priority?.name}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data, value) => {
        return <span>{value?.status?.name}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        const { slug, uuid } = record;
        return (
          <div className="actions">
            <Link to={`task/${uuid}`} className="preview">
              <Tooltip title="View Task">
                <FontAwesomeIcon icon={faEye} />
              </Tooltip>
            </Link>
            <span className="update" onClick={() => handleUpdateModal(record)}>
              <Tooltip title="Update Task">
                <EditOutlined />
              </Tooltip>
            </span>
            {clientRole == "05857c29-5129-4aed-ae5e-30f0155fdbf3" ||
            clientRole == "33e2bba8-73ef-44f0-a6ab-60ff5146b74f" ? (
              <>
                <span>
                  <Tooltip title="Delete Task">
                    <DeleteOutlined
                      onClick={() => handleConfirmModal(record)}
                    />
                  </Tooltip>
                </span>
              </>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <button onClick={handleCreateModal}>Create Task</button>
            </div>
            <AdminDataTable
              data={data}
              columns={columns}
              pagination={{ ...paginationConfig, onChange: handlePageChange }}
            />
          </Col>
        </Row>
      </div>

      {createTaskModal && (
        <CreateTaskModal
          handleClose={handleCloseCreateModal}
          addTask={handleCreateTask}
        />
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Task?"}
          description={"Are you sure you want to delete this Task?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}

      {showUpdateModal && (
        <UpdateTaskModal
          handleClose={handleUpdateModalClose}
          selectedData={selectedData}
          updateData={handleUpdateData}
        />
      )}

      {loading && <DataLoader />}
    </>
  );
}

export default TaskManagement;
