import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Radio, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { CreateCompanyModal , UpdateCompanyModal , ConfirmationModal } from "../../components/Modals";
import DataLoader from "../../components/dataLoader";
import { getFormattedDateTime, getFormattedData } from "../../utils";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { CompanyContext } from "../../context/CompanyContext";
const { Title } = Typography;

function CompanyManagement() {

  const [createCompany, setCreateCompanyModal] = useState(false);
  const {getCompanyList , registerCompany , companyList , loading , deleteCompany , updateCompany } = useContext(CompanyContext);
  const [selectedData, setselectedData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [page, setPage] = useState(1);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const {meta , data} = companyList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };

  useEffect(() =>{
    getCompanyList(page, true);
} , [page]);

  console.log("This is My Data " + data);

    const handleCreateCompany = (data) => {
      registerCompany(data , page , true);
    };

    const handleCreateModal = () => {
      setCreateCompanyModal(true);
    }

    const handleCloseCreateModal = () => {
      setCreateCompanyModal(false);
    };

    const handlePageChange = (page, pageSize) => {
      setPage(page);
    };

    const handleConfirmModal = (data) => {
      setselectedData(data);
      setShowConfirmationModal(true);
    };

    const handleDeleteData = (id) => {
      deleteCompany(id, page, true);
    };

    const handleConfirmationModalClose = () => {
      setShowConfirmationModal(false);
    };

    const handleUpdateModal = (data) => {
      setselectedData(data);
      setShowUpdateModal(true);
    };

    const handleUpdateModalClose = () => {
      setShowUpdateModal(false);
    };

    const handleUpdateData = (data, id) => {
      updateCompany(data, id, page, true);
    };

    const columns = [
      {
        title: "No",
        dataIndex: "No",
        key: "No",
        render: (data, value, i) => (
          <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
        ),
      },
      {
        title: "Company Name",
        dataIndex: "name",
        key: "name",
        render: (data, value) => {
          return <span>{value?.name}</span>;
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (data, value) => {
          return <span>{value?.user?.email}</span>;
        },
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        render: (data, value) => {
          return <span>{value?.phone}</span>;
        },
      },
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        render: (data, value) => {
          return <span>{value?.country?.name}</span>;
        },
      },
      {
        title: "Activated",
        dataIndex: "is_active",
        key: "user",
        render: (data, value) => {
          return <span>{value?.user?.is_active ? "Active" : "Inactive"}</span>;
        },
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        render: (data, value) => {
          return <span>{getFormattedDateTime(data)}</span>;
        },
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => {
          const { slug, uuid } = record;
          return (
        <div className="actions">
  
            <Link to={`company/${uuid}`} className="preview">
               <Tooltip title="View Company">
                 <FontAwesomeIcon icon={faEye} />
               </Tooltip>
             </Link>
             <span className="update" onClick={() => handleUpdateModal(record)}>
               <Tooltip title="Update Company">
                 <EditOutlined />
               </Tooltip>
             </span>
              <span>
                <Tooltip title="Delete Company">
                  <DeleteOutlined onClick={() => handleConfirmModal(record)} />
                </Tooltip>
              </span>
            </div>
          );
        },
      },
    ];


  return (
    <>
      <div className="layout-content">
      <Row gutter={[24, 0]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
      <div className="add-btn">
              <button onClick={handleCreateModal}>Create Company</button>
            </div>
            <AdminDataTable
                data={data}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
        </Col>
        </Row>
      </div>

      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Company?"}
          description={"Are you sure you want to delete this Company?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}

      {createCompany && (
        <CreateCompanyModal
          handleClose={handleCloseCreateModal}
          addCompany={handleCreateCompany}
        />
      )}

{showUpdateModal && (
        <UpdateCompanyModal
          handleClose={handleUpdateModalClose}
          selectedData={selectedData}
          updateData={handleUpdateData}
        />
      )}
      {loading && <DataLoader />}
      </>
  );
}

export default CompanyManagement;