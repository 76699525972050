import React, { useEffect, useState, useContext } from "react";
import { Card, Col, Row } from "antd";
import AdminAnalayticsCard from "../../components/Layouts/sharedAdminLayout/partials/analytics-card";
import { ProjectContext } from "../../context/ProjectContext";
import { ClientContext } from "../../context/ClientContext";
import { TaskContext } from "../../context/TaskContext";
import {
  faMedkit,
  faProjectDiagram,
  faTasks,
  faUser,
  faUserCheck,
  faUsers,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
function AdminDashboard() {
  const { getAllProjectList, allProjectList } = useContext(ProjectContext);
  const { getAllClientList, allUsersList } = useContext(ClientContext);
  const { getTaskList, taskList } = useContext(TaskContext);
  const [clientUuid] = useState(localStorage.getItem("crm_user_uuid"));
  const [companyUuid] = useState(localStorage.getItem("crm_company_uuid"));
  const [clientRole, setClientRole] = useState(null);

  useEffect(() => {
    setClientRole(localStorage.getItem("crm_role"));
  }, []);

  let initAnalytics = [
    {
      title: "Projects",
      thumb: faUser,
      amount: 0,
    },
    {
      title: "Clients",
      thumb: faUsers,
      amount: 0,
    },
    {
      title: "Tasks",
      thumb: faUserCheck,
      amount: 0,
    },
    {
      title: "Care Plans",
      thumb: faUserSlash,
      amount: 0,
    },
  ];
  const [analytics, setAnalytics] = useState(initAnalytics);

  // Bar Chart Options
  // const [eChartData, setEChartData] = useState({
  //   labels:[],
  //   values:[],
  //   totalCrews:0,
  //   totalFAs:0,
  //   totalCaptains:0,
  //   totalOfficers:0,
  // })

  // PieChart Options
  // const [pieChartData, setPieChartData] = useState({
  //   labels:[],
  //   values:[],
  // })

  useEffect(() => {
    let clientProjectQuery = "";
    let companyProjectQuery = "";
    let clientTaskQuery = "";
    let companyClientQuery = "";
    if (clientUuid) {
      clientProjectQuery = `?client_uuid=${clientUuid}`;
      clientTaskQuery = `/clients/${clientUuid}`;
    }
    if (companyUuid) {
      clientTaskQuery = `/companies/${companyUuid}`;
      companyClientQuery = `/companies/${companyUuid}`;
      companyProjectQuery = `/companies/${companyUuid}`;
    }
    getAllProjectList(clientProjectQuery, companyProjectQuery);
    getTaskList(1, false, clientTaskQuery);
    getAllClientList(companyClientQuery);
  }, []);

  useEffect(() => {
    if (clientRole == "225344f7-62b8-48b5-8e4c-274203687539") {
      // This role is client role
      // setting analytics for top

      setAnalytics([
        {
          title: "Projects",
          thumb: faProjectDiagram,
          amount: allProjectList.length,
        },
        {
          title: "Tasks",
          thumb: faTasks,
          amount: taskList.length,
        },
      ]);
    } else if (clientRole == "33e2bba8-73ef-44f0-a6ab-60ff5146b74f") {
      // This role is company role
      setAnalytics([
        {
          title: "Projects",
          thumb: faProjectDiagram,
          amount: allProjectList.length,
        },
        {
          title: "Clients",
          thumb: faUsers,
          amount: allUsersList.length,
        },
        {
          title: "Tasks",
          thumb: faTasks,
          amount: taskList.length,
        },
      ]);
    } else {
      // This role is super admin role
      setAnalytics([
        {
          title: "Projects",
          thumb: faProjectDiagram,
          amount: allProjectList.length,
        },
        {
          title: "Clients",
          thumb: faUsers,
          amount: allUsersList.length,
        },
        {
          title: "Tasks",
          thumb: faTasks,
          amount: taskList.length,
        },
      ]);
    }
  }, [allProjectList, allUsersList, taskList]);

  //     // setting BarChart
  //     setEChartData({
  //       labels:dashboardStats.signups.labels?dashboardStats.signups.labels.reverse():[],
  //       values:dashboardStats.signups.values?dashboardStats.signups.values.reverse():[],
  //       totalCrews:dashboardStats.total_crews?dashboardStats.total_crews:0,
  //       totalFAs:dashboardStats.total_fa?dashboardStats.total_fa:0,
  //       totalCaptains:dashboardStats.total_captain?dashboardStats.total_captain:0,
  //       totalOfficers:dashboardStats.total_first_officer?dashboardStats.total_first_officer:0
  //     });

  //     // setting PieChart
  //     // parse int values
  //     var a = dashboardStats.referrals.values?dashboardStats.referrals.values:[];
  //     var results = a.map(function (x) {
  //       return parseInt(x, 10);
  //     });
  //     setPieChartData({
  //       labels:dashboardStats.referrals.labels?dashboardStats.referrals.labels:[],
  //       values:results,
  //     });

  //   }
  // },[dashboardStats]);

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {analytics.map((item, index) => (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
              key={index}
            >
              <AdminAnalayticsCard
                title={item.title}
                thumb={item.thumb}
                total={item.amount}
              />
            </Col>
          ))}
        </Row>

        {/* <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Echart eChart={eChartData} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <PieChart pieChart={pieChartData}/>
            </Card>
          </Col>
        </Row> */}
      </div>
    </>
  );
}

export default AdminDashboard;
