import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { StatusContext } from "../../../context/StatusContext";
import { ClientContext } from "../../../context/ClientContext";

const CreateInvoiceModal = ({ handleClose, addInvoice, client_uuid }) => {
  const { getStatusList, statusList } = useContext(StatusContext);
  const { getAllClientList, allUsersList } = useContext(ClientContext);
  const [companyUuid] = useState(localStorage.getItem("crm_company_uuid"));
  const [form] = Form.useForm();

  useEffect(() => {
    getStatusList("?type=invoice");
    let companyQuery = "";

    if (companyUuid) {
      companyQuery = `/companies/${companyUuid}`;
    }
    getAllClientList(companyQuery);

    if (client_uuid) {
      form.setFieldsValue({
        client_uuid: client_uuid,
      });
    }
  }, []);

  const handleFormSubmit = () => {
    const data = form.getFieldsValue();

    addInvoice(data);
    handleClose();
  };

  return (
    <Modal
      visible={true}
      title={<span>Create Invoice</span>}
      centered
      onCancel={handleClose}
      footer={null}
      width={750}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
              <Col xs={24} sm={24}>
                <label className="title">
                  Client <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"client_uuid"}
                  rules={[
                    { required: true, message: "Please select your Client!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="client_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {allUsersList?.map((client, index) => (
                      <Option value={client.uuid} key={index}>
                        {client.user.fullname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Submit
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateInvoiceModal;
