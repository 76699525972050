import React, { createContext, useState } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";

export const StatusContext = createContext();

export const StatusContextProvider = ({ children }) => {
  const [statusList, setStatusList] = useState([]);
  const [allStatusList, setAllStatusList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllStatusList = async (page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/statuses?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      setAllStatusList(res?.data);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const getStatusList = async (type = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/statuses${type}`,
        BEARER_TOKEN_HEADERS
      );
      setStatusList(res?.data);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const registerStatus = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/statuses`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message ? res.data.message : "Status added successfully"
      );
      getAllStatusList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const updateStatus = async (data, page, paginationStatus, id) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/statuses/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message ? res.data.message : "Status Update successfully"
      );
      getAllStatusList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const deleteStatus = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/statuses/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAllStatusList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  return (
    <StatusContext.Provider
      value={{
        getStatusList,
        getAllStatusList,
        registerStatus,
        deleteStatus,
        allStatusList,
        statusList,
        updateStatus,
        loading,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};
