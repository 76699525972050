import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { CountryContext } from "../../../context/CountryContext";
import ImageUploader from "../../imageUploader";

const CreateCompanyModal = ({ handleClose, addCompany }) => {
  const { allCountryList, getAllCountryList } = useContext(CountryContext);
  const [file, setFile] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    getAllCountryList();
  }, []);

  useEffect(() => {
    console.log(allCountryList);
  }, [allCountryList]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFormSubmit = () => {
    const data = form.getFieldsValue();

    const formData = new FormData();
    formData.append("logo", file);
    formData.append("firstname", data.firstname);
    formData.append("lastname", data.lastname);
    formData.append("password", data.password);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("address", data.address);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("zip", data.zip);
    formData.append("country_uuid", data.country_uuid);
    formData.append("is_active", data.is_active ? 1 : 0);

    addCompany(formData);
    handleClose();
  };

  return (
    <Modal
      visible={true}
      title={<span>Create Company</span>}
      centered
      onCancel={handleClose}
      footer={null}
      width={820}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
              <Col xs={24} sm={24}>
                <label className="title">
                  Logo<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item name="logo">
                  <Input name="logo" type="file" onChange={handleFileChange} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <label className="title">
                  First Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input name="firstname" placeholder="First name..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <label className="title">
                  Last Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"lastname"}
                  rules={[
                    { required: true, message: "Please input your last name!" },
                  ]}
                >
                  <Input name="lastname" placeholder="Last name..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <label className="title">
                  Password<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"password"}
                  rules={[
                    { required: true, message: "Please input your password!" },
                    { min: 6, message: "Password must be 6 character long" },
                  ]}
                >
                  <Input.Password
                    name="password"
                    autoComplete="new-password"
                    placeholder="Password..."
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <label className="title">
                  Company Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"name"}
                  rules={[
                    { required: true, message: "Please input your Name!" },
                  ]}
                >
                  <Input name="name" placeholder="Name..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      type: "email",
                      message: "Please Enter Valid email Address!",
                    },
                    { required: true, message: "Please input your Email!" },
                  ]}
                >
                  <Input name="email" placeholder="Email..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  Phone<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"phone"}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Phone Number!",
                    },
                  ]}
                >
                  <Input name="phone" placeholder="Phone Number..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  Address<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"address"}
                  rules={[
                    { required: true, message: "Please input your Address!" },
                  ]}
                >
                  <Input name="address" placeholder="Address..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  City<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"city"}
                  rules={[
                    { required: true, message: "Please input your City!" },
                  ]}
                >
                  <Input name="city" placeholder="City..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  State<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"state"}
                  rules={[
                    { required: true, message: "Please input your State!" },
                  ]}
                >
                  <Input name="state" placeholder="State..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <label className="title">
                  Zip Code<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"zip"}
                  rules={[
                    { required: true, message: "Please input your zip code!" },
                  ]}
                >
                  <Input name="zip" placeholder="Zip Code..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Country <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"country_uuid"}
                  rules={[
                    { required: true, message: "Please select your Country!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="country_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {allCountryList?.map((country, index) => (
                      <Option value={country.uuid} key={index}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <Form.Item name="is_active" valuePropName="checked">
                  <Checkbox checked={true}>Is active?</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Submit
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateCompanyModal;
