import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import AdminSharedLayout from "../../components/Layouts/sharedAdminLayout/index";

const AdminPrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("crm_token");
  const role = localStorage.getItem("crm_role");

  if (token && role) {
    return (
      <AdminSharedLayout>
        <Route exact {...rest} render={(props) => <Component {...props} />} />
      </AdminSharedLayout>
    );
  } else {
    return <Redirect to="/" />;
  }
};

export default AdminPrivateRoute;
