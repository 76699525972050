import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Loader/index";
import {
  Row,
  Col,
  Card,
  Typography,
  Collapse,
  Tabs,
  Modal,
  Space,
  Avatar,
  Tooltip,
} from "antd";
import { InvoiceContext } from "../../../context/InvoiceContext";
import AdminDataTable from "../../Layouts/sharedAdminLayout/partials/data-table";
import { getFormattedDateTime } from "../../../utils";

const Invoice = () => {
  const { TabPane } = Tabs;
  const { uuid } = useParams();
  const { Title, Paragraph } = Typography;
  const { getSingleInvoice, data, singleInvoiceLoader, transactions } =
    useContext(InvoiceContext);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    console.log(data);
    getSingleInvoice(uuid);
  }, []);

  const handleProceedToPayment = () => {
    console.log("Good");
  };


  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (transactions, value, i) => <b>{i + 1}</b>,
    },
    {
      title: "Transaction Name",
      dataIndex: "transaction_name",
      key: "transaction_name",
      render: (transactions, value) => {
        return <span>{value?.transaction_object?.title}</span>;
      },
    },
    {
      title: "Subtotal",
      dataIndex: "transaction_total",
      key: "transaction_total",
      render: (transactions, value) => {
        return <span>${value?.cost}</span>;
      },
    },
  ];

  if (singleInvoiceLoader) {
    return <Loader />;
  } else {
    const tab1Sec = () => (
      <>
        <Row gutter={[6, 0]}>
          <Col xs={24} sm={24} md={12} lg={18} xl={18}>
            <div className="profile-details">
              <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Invoice Number</h5>
                  <p className="detail-description">
                    #{data?.id}
                  </p>
                </Col>

                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Client</h5>
                  <p className="detail-description">
                    {data?.client?.user?.fullname}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Status</h5>
                  <p className="detail-description">{data?.status?.name}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Grand Total</h5>
                  <p className="detail-description">${data?.total}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <h5 className="detail-title">Created At</h5>
                  <p className="detail-description">{getFormattedDateTime(data?.created_at)}</p>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <h5 className="detail-title">Updated At</h5>
                  <p className="detail-description">{getFormattedDateTime(data?.updated_at)}</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {modalVisible && (
          <div className="layout-content">
            <div className="mb-24">
              <Card bordered={false} className="criclebox">
                {/* Rest of the code */}
              </Card>
            </div>

            <Modal
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              footer={null}
            >
              <img
                src={largeImageUrl}
                alt="Large Avatar"
                style={{ width: "100%" }}
              />
            </Modal>
          </div>
        )}
      </>
    );
    const noData = (message) => <p className="no-data">{message}</p>;

    return (
      <div className="layout-content">
        <div className="mb-24">
          <Card bordered={false} className="criclebox">
            <Title level={5}>{data?.headline}</Title>
            <Paragraph className="lastweek" style={{ marginBottom: 5 }}>
              {data?.brief}
            </Paragraph>
            <Tabs defaultActiveKey="1" style={{ marginTop: 20 }}>
              <TabPane tab="Detail" key="1">
                {tab1Sec()}
              </TabPane>
            </Tabs>
          </Card>
        </div>
        <div className="mb-24">
          <Card bordered={false} className="criclebox">
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <h3>Transactions</h3>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                <AdminDataTable data={transactions} columns={columns} />
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="proceed-to-pyment">
                  <button onClick={handleProceedToPayment}>Proceed To Payment</button>
                </div>
              </Col>
            </Row>


          </Card>
        </div>
      </div>
    );
  }
};

export default Invoice;
