import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Loader/index";
import {
  Row,
  Col,
  Card,
  Typography,
  Collapse,
  Tabs,
  Modal,
  Space,
  Avatar,
  Tooltip,
} from "antd";
import { ProductContext } from "../../../context/ProductContext";
import { getFormattedDateTime } from "../../../utils";

const Product = () => {
  const { TabPane } = Tabs;
  const { uuid } = useParams();
  const { Title, Paragraph } = Typography;
  const { getSingleProduct, data, singleProductLoaders } =
    useContext(ProductContext);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    console.log(data);
    getSingleProduct(uuid);
  }, []);

  if (singleProductLoaders) {
    return <Loader />;
  } else {
    const tab1Sec = () => (
      <>
        <Row gutter={[6, 0]}>
          <Col xs={24} sm={24} md={12} lg={18} xl={18}>
            <div className="profile-details">
              <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Name</h5>
                  <p className="detail-description">{data?.name}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Status</h5>
                  <p className="detail-description">{data?.status?.name}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Product Type</h5>
                  <p className="detail-description">
                    {data?.product_type?.name}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Cost</h5>
                  <p className="detail-description">{data?.cost}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Status</h5>
                  <p className="detail-description">{data?.status?.name}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Created At</h5>
                  <p className="detail-description">
                    {getFormattedDateTime(data?.created_at)}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Updated At</h5>
                  <p className="detail-description">
                    {getFormattedDateTime(data?.updated_at)}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {modalVisible && (
          <div className="layout-content">
            <div className="mb-24">
              <Card bordered={false} className="criclebox">
                {/* Rest of the code */}
              </Card>
            </div>

            <Modal
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              footer={null}
            >
              <img
                src={largeImageUrl}
                alt="Large Avatar"
                style={{ width: "100%" }}
              />
            </Modal>
          </div>
        )}
      </>
    );

    const tab2Sec = () => (
      <>
        <Row gutter={[6, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <p
              className="detail-description"
              style={{
                whiteSpace: "pre-wrap",
                fontFamily: "Arial",
                fontSize: "14px",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            >
              {data?.description}
            </p>
          </Col>
        </Row>
      </>
    );

    const noData = (message) => <p className="no-data">{message}</p>;

    return (
      <div className="layout-content">
        <div className="mb-24">
          <Card bordered={false} className="criclebox">
            <Title level={5}>{data?.headline}</Title>
            <Paragraph className="lastweek" style={{ marginBottom: 5 }}>
              {data?.brief}
            </Paragraph>
            <Tabs defaultActiveKey="1" style={{ marginTop: 20 }}>
              <TabPane tab="Detail" key="1">
                {tab1Sec()}
              </TabPane>
              <TabPane tab="Description" key="2">
                {tab2Sec()}
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    );
  }
};

export default Product;
