import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { StatusContext } from "../../../context/StatusContext";
import { ClientContext } from "../../../context/ClientContext";
import { BillingFrequencyContext } from "../../../context/BillingFrequencyContext";
import { SubscriptionTypeContext } from "../../../context/SubscriptionTypeContext";

const CreateSubscriptionModal = ({
  handleClose,
  addSubscription,
  client_uuid,
}) => {
  const { getStatusList, statusList } = useContext(StatusContext);
  const { getAllClientList, allUsersList } = useContext(ClientContext);
  const { getSubscriptionTypeList, subscriptionTypeList } = useContext(
    SubscriptionTypeContext
  );
  const { getBillingFrequencyList, billingfrequencyLists } = useContext(
    BillingFrequencyContext
  );
  const [form] = Form.useForm();

  useEffect(() => {
    getStatusList("?type=subscription");
    getAllClientList();
    getBillingFrequencyList();
    getSubscriptionTypeList();

    if (client_uuid) {
      form.setFieldsValue({
        client_uuid: client_uuid,
      });
    }
  }, []);

  const handleFormSubmit = () => {
    const data = form.getFieldsValue();

    addSubscription(data);
    handleClose();
  };

  return (
    <Modal
      visible={true}
      title={<span>Create Subscription</span>}
      centered
      onCancel={handleClose}
      footer={null}
      width={750}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <label className="title">
                  Client <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"client_uuid"}
                  rules={[
                    { required: true, message: "Please select your Client!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="client_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {allUsersList?.map((client, index) => (
                      <Option value={client.uuid} key={index}>
                        {client.user.fullname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Billing Frequency <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"billing_frequency_uuid"}
                  rules={[
                    {
                      required: true,
                      message: "Please select your Billing Frequency!",
                    },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="billing_frequency_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {billingfrequencyLists?.map((billingFrequency, index) => (
                      <Option value={billingFrequency.uuid} key={index}>
                        {billingFrequency.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Subscription Type <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"subscription_type_uuid"}
                  rules={[
                    {
                      required: true,
                      message: "Please select your Subscription Type!",
                    },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="subscription_type_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {subscriptionTypeList?.map((subscriptionType, index) => (
                      <Option value={subscriptionType.uuid} key={index}>
                        {subscriptionType.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Status <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"status_uuid"}
                  rules={[
                    { required: true, message: "Please select your Status!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="status_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {statusList?.map((status, index) => (
                      <Option value={status.uuid} key={index}>
                        {status.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Start Date<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      message: "Please Select your Start Date!",
                    },
                  ]}
                >
                  <Input name="start_date" type="date" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Submit
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateSubscriptionModal;
