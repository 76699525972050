import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer } from "antd";
import Clientnav from "./partials/Clientnav";
import Companynav from "./partials/Companynav";
import Sidenav from "./partials/Sidenav";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import "./index.scss";

const { Header: AntHeader, Content, Sider } = Layout;

function AdminSharedLayout({ children }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const [visible, setVisible] = useState(false);
  const openDrawer = () => setVisible(!visible);
  const [clientRole , setClientRole] = useState(null);
  const Logout = () => {
    localStorage.removeItem("crm_token");
    localStorage.removeItem("crm_role");
    localStorage.removeItem("crm_user_uuid");
    localStorage.removeItem("crm_company_uuid");
    location.reload();
  };
  useEffect(() =>{
    setClientRole(localStorage.getItem('crm_role'));
  } , []);
  return (
    <section className="dashboard-wrapper">
      <Layout className="layout-dashboard">
        <Drawer
          title={false}
          placement="left"
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
          key="left"
          width={250}
          className="drawer-sidebar"
        >
          <Layout className="layout-dashboard">
            <Sider
              trigger={null}
              width={250}
              theme="light"
              className="sider-primary ant-layout-sider-primary"
            >
            {clientRole == "05857c29-5129-4aed-ae5e-30f0155fdbf3" && (
              <>
              <Sidenav page={page} logout={Logout} />
              </>
             )}
             {clientRole == "225344f7-62b8-48b5-8e4c-274203687539" &&(  
              <>
             <Clientnav page={page} logout={Logout} />
              </>
             )}
             {clientRole == "33e2bba8-73ef-44f0-a6ab-60ff5146b74f" &&(  
              <>
             <Companynav page={page} logout={Logout} />
              </>
             )}
            </Sider>
          </Layout>
        </Drawer>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          trigger={null}
          width={250}
          theme="light"
          className="sider-primary ant-layout-sider-primary"
        >
          {clientRole == "05857c29-5129-4aed-ae5e-30f0155fdbf3" && (
              <>
              <Sidenav page={page} color="#2992d4" logout={Logout} />
              </>
             )}
             {clientRole == "225344f7-62b8-48b5-8e4c-274203687539" &&(  
              <>
             <Clientnav page={page} color="#2992d4" logout={Logout} />
              </>
             )}
             {clientRole == "33e2bba8-73ef-44f0-a6ab-60ff5146b74f" &&(  
              <>
             <Companynav page={page} color="#2992d4" logout={Logout} />
              </>
             )}
        </Sider>
        <Layout>
          <AntHeader>
            <Header
              onPress={openDrawer}
              page={page.replaceAll("-", " ")}
              logout={Logout}
            />
          </AntHeader>
          <Content className="content-ant">
            {children}
            <Footer />
          </Content>
        </Layout>
      </Layout>
    </section>
  );
}

export default AdminSharedLayout;
