import React from "react";
import { Row, Col, Breadcrumb, Button } from "antd";
import { NavLink } from "react-router-dom";

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({ onPress, page, logout }) {
  const title = page.split("/");
  const handleLogout = () => {
    logout();
  };
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={16} md={12}>
          {title[1] === "dashboard" ? (
            <Breadcrumb>
              <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
                Hello! Welcome Back
              </Breadcrumb.Item>
            </Breadcrumb>
          ) : (
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/admin/dashboard">Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
                {title[1]}
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {title.length === 4 ? title[2].replaceAll("-", " ") : title[1]}
            </span>
          </div>
        </Col>
        <Col span={8} md={12} className="header-control">
          {/* <Badge size="small" count={4}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                href="#pablo"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {bell}
              </a>
            </Dropdown>
          </Badge> */}
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>

          {/* <Input
            className="header-search"
            placeholder="Type here..."
            prefix={<SearchOutlined />}
          /> */}
          <button className="logout-btn" onClick={logout}>
            Logout
          </button>
        </Col>
      </Row>
    </>
  );
}

export default Header;
