import React from "react";
import "./styles.scss";
const DataLoader = () => {
  return (
    <div className="dataloader-container">
      <div className="stage">
        <div className="dot-carousel" />
      </div>
    </div>
  );
};

export default DataLoader;
