import React, { useState, createContext } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";

export const CrewContext = createContext();

export const CrewContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [crewsList, setCrewsList] = useState([]);
  const [singleCrew, setSingleCrew] = useState(null);
  const [singleCrewLoader, setSingleCrewLoader] = useState(true);
  const getCrewsList = async (page, paginationStatus,query = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/crews?page=${page}&paginate=${paginationStatus}${query}`,
        BEARER_TOKEN_HEADERS
      );
      setLoading(false);
      console.log("crew list result", res?.data);
      setCrewsList(res?.data);
    } catch (err) {
      setLoading(false);
      console.log("err", err?.response);
      errorToast(err);
    }
  };

  const getSingleCrew = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/crews/${id}`,
        BEARER_TOKEN_HEADERS
      );
      console.log("crew list result", res?.data);
      setSingleCrew(res?.data);
      setSingleCrewLoader(false);
    } catch (err) {
      errorToast(err);
      setSingleCrewLoader(false);
    }
  };

  const createCrewsList = async (data, page, paginationStatus) => {
    console.log("crew data", data);
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/crews`,
        data,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getCrewsList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const updateCrew = async (data, id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/crews/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("update Crew result", res?.data);
      toast.success(res?.data?.message);
      getCrewsList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      errorToast(err);
    }
  };


  const deleteCrew = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/crews/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getCrewsList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };
  return (
    <CrewContext.Provider
      value={{
        getSingleCrew,
        getCrewsList,
        createCrewsList,
        updateCrew,
        deleteCrew,
        crewsList,
        data: singleCrew,
        singleCrewLoader,
        loading,
      }}
    >
      {children}
    </CrewContext.Provider>
  );
};
