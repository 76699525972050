import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { CountryContext } from "../../../context/CountryContext";
import { CompanyContext } from "../../../context/CompanyContext";

const UpdateClientModal = ({ handleClose, selectedData, updateData }) => {
  const { allCountryList, getAllCountryList } = useContext(CountryContext);
  const { allCompanyList, getAllCompanyList } = useContext(CompanyContext);
  const [companyUuid] = useState(localStorage.getItem("crm_company_uuid"));
  const [photo, setPhoto] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    getAllCountryList();
    getAllCompanyList();
  }, []);

  useEffect(() => {
    console.log(allCountryList);
  }, [allCountryList]);

  useEffect(() => {
    console.log("Selected Client", selectedData);
    form.setFieldsValue({
      firstname: selectedData.user.firstname,
      lastname: selectedData.user.lastname,
      email: selectedData.user.email,
      phone: selectedData.phone,
      address: selectedData.address,
      city: selectedData.city,
      state: selectedData.state,
      zip: selectedData.zip,
      country_uuid: selectedData.country.uuid,
      is_active: selectedData.user.is_active,
      company_uuid: selectedData.company_uuid,
      // newsletter:selectedData.user.newsletter,
    });
  }, []);

  const handleFormSubmit = () => {
    const data = form.getFieldsValue();
    let tempData = {
      company_uuid: companyUuid ? companyUuid : data.company_uuid,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      password: data.password,
      phone: data.phone,
      address: data.address,
      city: data.city,
      state: data.state,
      zip: data.zip,
      country_uuid: data.country_uuid,
      is_active: data.is_active ? data.is_active : false,
      user_uuid: selectedData.user.uuid,
    };
    console.log(tempData);
    updateData(tempData, selectedData.uuid);
    handleClose();
  };
  return (
    <Modal
      visible={true}
      title={<span>Update Client</span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
              {!companyUuid && (
                <>
                  <Col xs={24} sm={24}>
                    <label className="title">
                      Company <span style={{ color: "red" }}>*</span>
                    </label>
                    <Form.Item
                      name={"company_uuid"}
                      rules={[
                        {
                          required: true,
                          message: "Please select your Company!",
                        },
                      ]}
                    >
                      <Select
                        placeholder=""
                        name="company_uuid"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {allCompanyList?.map((company, index) => (
                          <Option value={company.uuid} key={index}>
                            {company.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col xs={24} sm={12}>
                <label className="title">
                  First Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input name="firstname" placeholder="First name..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Last Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"lastname"}
                  rules={[
                    { required: true, message: "Please input your last name!" },
                  ]}
                >
                  <Input name="lastname" placeholder="Last name..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"email"}
                  rules={[
                    { type: "email", message: "Enter a valid email" },
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input name="email" placeholder="Email..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <label className="title">
                  Password<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"password"}
                  rules={[
                    { min: 6, message: "Password must be 6 character long" },
                  ]}
                >
                  <Input.Password
                    name="password"
                    autoComplete="new-password"
                    placeholder="Password..."
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <label className="title">
                  Phone<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"phone"}
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    name="phone"
                    autoComplete="phone"
                    placeholder="Phone..."
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <label className="title">
                  Address<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"address"}
                  rules={[
                    { required: true, message: "Please input your address!" },
                  ]}
                >
                  <Input
                    name="address"
                    autoComplete="address"
                    placeholder="Address..."
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <label className="title">
                  City<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"city"}
                  rules={[
                    { required: true, message: "Please input your city!" },
                  ]}
                >
                  <Input
                    name="city"
                    autoComplete="city"
                    placeholder="City..."
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <label className="title">
                  State<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"state"}
                  rules={[
                    { required: true, message: "Please input your state!" },
                  ]}
                >
                  <Input
                    name="state"
                    autoComplete="state"
                    placeholder="State..."
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <label className="title">
                  Zip Code<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"zip"}
                  rules={[
                    { required: true, message: "Please input your zip code!" },
                  ]}
                >
                  <Input
                    type="number"
                    name="zip"
                    autoComplete="zip"
                    placeholder="Zip Code..."
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Country <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"country_uuid"}
                  rules={[
                    { required: true, message: "Please select your Country!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="country_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {allCountryList?.map((country, index) => (
                      <Option value={country.uuid} key={index}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <Form.Item name="is_active" valuePropName="checked">
                  <Checkbox checked={true}>Is active?</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Update
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UpdateClientModal;
