import React, { createContext, useState } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";

export const InvoiceContext = createContext();

export const InvoiceContextProvider = ({ children }) => {
  const [allInvoiceList, setAllInvoiceList] = useState([]);
  const [allClientInvoiceList, setClientAllInvoiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [singleInvoiceLoader, setSingleInvoiceLoader] = useState(false);
  const [singleInvoice, setSingleInvoice] = useState(null);
  const [singleInvoiceTransactions, setSingleInvoiceTransactions] =
    useState(null);

  const getAllInvoiceList = async (
    page,
    paginationStatus,
    clientQuery = ""
  ) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/invoices?page=${page}&paginate=${paginationStatus}${clientQuery}`,
        BEARER_TOKEN_HEADERS
      );
      setAllInvoiceList(res?.data);
      console.log(res);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const registerInvoice = async (uuid, data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/invoices`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message ? res.data.message : "Invoice added successfully"
      );
      getAllInvoiceList(page, paginationStatus);
      if (uuid) {
        getAllClientInvoiceList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const updateInvoice = async (uuid, data, page, paginationStatus, id) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/invoices/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message ? res.data.message : "Invoice Update successfully"
      );
      getAllInvoiceList(page, paginationStatus);
      if (uuid) {
        getAllClientInvoiceList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const deleteInvoice = async (uuid, id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/invoices/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAllInvoiceList(page, paginationStatus);

      if (uuid) {
        getAllClientInvoiceList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getSingleInvoice = async (id) => {
    setSingleInvoiceLoader(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/invoices/${id}`,
        BEARER_TOKEN_HEADERS
      );
      setSingleInvoice(res?.data);
      console.log("Invoice result", res?.data);
      setSingleInvoiceLoader(false);
      setSingleInvoiceTransactions(res?.data?.transactions);
    } catch (err) {
      errorToast(err);
      setSingleInvoiceLoader(false);
    }
  };

  const getAllClientInvoiceList = async (uuid, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/invoices?page=${page}&paginate=${paginationStatus}&client_uuid=${uuid}`,
        BEARER_TOKEN_HEADERS
      );
      setClientAllInvoiceList(res?.data);
      console.log(res);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  return (
    <InvoiceContext.Provider
      value={{
        getAllClientInvoiceList,
        getAllInvoiceList,
        registerInvoice,
        getSingleInvoice,
        data: singleInvoice,
        singleInvoiceLoader,
        allClientInvoiceList,
        deleteInvoice,
        allInvoiceList,
        updateInvoice,
        transactions: singleInvoiceTransactions,
        loading,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};
