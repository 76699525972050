import React, { createContext, useState } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";

export const ProductContext = createContext();

export const ProductContextProvider = ({ children }) => {
  const [allProductList, setAllProductList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [singleProductLoader, setSingleProductLoader] = useState(false);
  const [singleProduct, setSingleProduct] = useState(null);

  const getAllProductList = async (page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/products?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      setAllProductList(res?.data);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const getProductList = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/products`,
        BEARER_TOKEN_HEADERS
      );
      setProductList(res?.data);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const registerProduct = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/products`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message ? res.data.message : "Product added successfully"
      );
      getAllProductList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const updateProduct = async (data, page, paginationStatus, id) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/products/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message ? res.data.message : "Product Update successfully"
      );
      getAllProductList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const deleteProduct = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/products/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAllProductList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getSingleProduct = async (id) => {
    setSingleProductLoader(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/products/${id}`,
        BEARER_TOKEN_HEADERS
      );
      setSingleProduct(res?.data);
      console.log("Subscription result", res?.data);
      setSingleProductLoader(false);
    } catch (err) {
      errorToast(err);
      setSingleProductLoader(false);
    }
  };

  return (
    <ProductContext.Provider
      value={{
        getAllProductList,
        getProductList,
        registerProduct,
        getSingleProduct,
        data: singleProduct,
        singleProductLoader,
        deleteProduct,
        allProductList,
        productList,
        updateProduct,
        loading,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
