import React, { useState, createContext } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";

export const CountryContext = createContext();

export const CountryContextProvider = ({ children }) => {
  const [countryList, setCountryList] = useState([]);
  const [allCountryList, setAllCountryList] = useState([]);
  const [loading, setLoading] = useState(false);
    const getCountryList = async (page , paginationStatus) => {
      setLoading(true);
        try {
            const res = await axios.get(
              `${process.env.REACT_APP_BACKEND_ENV}/countries?page=${page}&paginate=${paginationStatus}`,
              BEARER_TOKEN_HEADERS
            );
            setLoading(false);
            setCountryList(res?.data);
          } catch (err) {
            setLoading(false);
            errorToast(err);
          }
        console.log("OK");
    };

    const getAllCountryList = async () => {
      setLoading(true);
        try {
            const res = await axios.get(
              `${process.env.REACT_APP_BACKEND_ENV}/countries`,
              BEARER_TOKEN_HEADERS
            );
            setLoading(false);
            setAllCountryList(res?.data);
          } catch (err) {
            setLoading(false);
            errorToast(err);
          }
        console.log("OK");
    };

    return (
      <CountryContext.Provider
      value={{
        getCountryList,
        getAllCountryList,
        allCountryList,
        loading,
        countryList
      }}
      >
      {children}
      </CountryContext.Provider>
    );
};