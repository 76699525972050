import React, { createContext, useState } from "react";
import axios from "axios";
import {
  errorToast,
  BEARER_TOKEN_HEADERS,
  BEARER_TOKEN_IMAGE_HEADERS,
} from "../utils";
import { toast } from "react-toastify";

export const GenericContext = createContext();

export const GenericContextProvider = ({ children }) => {
  const [countries, setCountries] = useState([]);

  const getCountries = async (page, paginationStatus) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/countries?page=1&paginate=true&limit=250`,
        BEARER_TOKEN_HEADERS
      );
      if (res?.data) {
        setCountries(res.data.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const uploadImage = async (folder, image, responseCallback) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/uploader/upload_single/${folder}`,
        image,
        BEARER_TOKEN_IMAGE_HEADERS
      );
      if (res.data) {
        responseCallback(res.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };
  return (
    <GenericContext.Provider
      value={{
        getCountries,
        countries,
        uploadImage,
      }}
    >
      {children}
    </GenericContext.Provider>
  );
};
