import { Input, Modal } from "antd";
import React, { useState } from "react";
import "../styles.scss";
const CreateRoleModal = ({ handleClose, addRoleType }) => {
  const [roleName, setRoleName] = useState("");
  const handleTypeChange = (e) => {
    setRoleName(e.target.value);
  };

  const handleAddRoleType = () => {
    addRoleType(roleName);
    handleClose();
  };
  return (
    <Modal
      visible={true}
      title={<span>Create Role </span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <label className="title">Name</label>
          <Input
            className="field"
            onChange={handleTypeChange}
            placeholder="Enter Name Here.."
          />
        </div>
        <div className="modal-footer">
          <button className="close" onClick={handleClose}>
            Close
          </button>
          <button className="submit" onClick={handleAddRoleType}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateRoleModal;
