import React, { createContext, useState } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";

export const ProductTypeContext = createContext();

export const ProductTypeContextProvider = ({ children }) => {
  const [allProductTypeList, setAllProductTypeList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllProductTypeList = async (page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/product_types?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      setAllProductTypeList(res?.data);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const getProductTypeList = async (page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/product_types?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      setProductTypeList(res?.data);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const registerProductType = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/product_types`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message
          ? res.data.message
          : "Product Type added successfully"
      );
      getAllProductTypeList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const updateProductType = async (data, page, paginationStatus, id) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/product_types/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message
          ? res.data.message
          : "Product Type Update successfully"
      );
      getAllProductTypeList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const deleteProductType = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/product_types/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAllProductTypeList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  return (
    <ProductTypeContext.Provider
      value={{
        getAllProductTypeList,
        getProductTypeList,
        productTypeList,
        registerProductType,
        deleteProductType,
        allProductTypeList,
        updateProductType,
        loading,
      }}
    >
      {children}
    </ProductTypeContext.Provider>
  );
};
