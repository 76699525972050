import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { StatusTypeContext } from "../../../context/StatusTypeContext";

const CreateStatusModal = ({ handleClose, addStatus }) => {
  const { getStatusTypeList, statusTypeList } = useContext(StatusTypeContext);

  const [form] = Form.useForm();

  useEffect(() => {
    getStatusTypeList();
  }, []);

  const handleFormSubmit = () => {
    const data = form.getFieldsValue();

    addStatus(data);
    handleClose();
  };

  return (
    <Modal
      visible={true}
      title={<span>Create Status</span>}
      centered
      onCancel={handleClose}
      footer={null}
      width={750}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
              <Col xs={24} sm={24}>
                <label className="title">
                  Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please Input your Status Name!",
                    },
                  ]}
                >
                  <Input name="name" type="text" placeholder="Name..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <label className="title">
                  Color<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="color"
                  rules={[
                    {
                      required: true,
                      message: "Please Input your Status Color!",
                    },
                  ]}
                >
                  <Input name="color" type="text" placeholder="Color..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <label className="title">
                  Status Type <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"status_type_uuid"}
                  rules={[
                    {
                      required: true,
                      message: "Please select your Status Type!",
                    },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="status_type_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {statusTypeList?.map((statustype, index) => (
                      <Option value={statustype.uuid} key={index}>
                        {statustype.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Submit
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateStatusModal;
