import React from "react";
import { Table } from "antd";
function AdminDataTable({ data, columns, pagination, expandable = null }) {
  const filteredData = data?.map((item, index) => {
    return { ...item, key: index + 1 };
  });
  return (
    <div className="ant-list-box table-responsive">
      <Table
        columns={columns}
        dataSource={filteredData}
        expandable={expandable}
        pagination={{ showSizeChanger: false, ...pagination }}
      />
    </div>
  );
}

export default AdminDataTable;
