import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Form, Input, Button, Tooltip } from "antd";
import { FilterFilled, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { getFormattedDateTime, getFormattedData } from "../../utils";
import DataLoader from "../../components/dataLoader";
import FilterDrawer from "../../components/filterDrawer";
import { InvoiceContext } from "../../context/InvoiceContext";
import { CreateInvoiceModal, ConfirmationModal } from "../../components/Modals";
const { Title } = Typography;

function InvoiceManagement() {
  const {
    getAllInvoiceList,
    allInvoiceList,
    registerInvoice,
    deleteInvoice,
    updateInvoice,
    loading,
  } = useContext(InvoiceContext);

  const [createInvoice, setCreateInvoiceModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [clientUuid] = useState(localStorage.getItem("crm_user_uuid"));
  const [selectedData, setselectedData] = useState(null);
  const [clientRole, setClientRole] = useState(null);

  const [page, setPage] = useState(1);
  const { meta, data } = allInvoiceList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };

  useEffect(() => {
    setClientRole(localStorage.getItem("crm_role"));
  }, []);

  useEffect(() => {
    let clientQuery = "";
    if (clientUuid) {
      clientQuery = `&client_uuid=${clientUuid}`;
    }
    getAllInvoiceList(page, true, clientQuery);
  }, [page]);

  const handleCreateModal = () => {
    setCreateInvoiceModal(true);
  };

  const handleCloseCreateModal = () => {
    setCreateInvoiceModal(false);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  const handleCreateInvoice = (data) => {
    registerInvoice("", data, page, true);
  };

  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteData = (id) => {
    deleteInvoice("", id, page, true);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (data, value) => {
        return <span>{value?.client?.user?.fullname}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data, value) => {
        return <span>{value?.status?.name}</span>;
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (data, value) => {
        return <span>${value?.total}</span>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        const { slug, uuid } = record;
        return (
          <div className="actions">
            <Link to={`invoice/${uuid}`} className="preview">
              <Tooltip title="View Invoice">
                <FontAwesomeIcon icon={faEye} />
              </Tooltip>
            </Link>

            {clientRole == "05857c29-5129-4aed-ae5e-30f0155fdbf3" ||
            clientRole == "33e2bba8-73ef-44f0-a6ab-60ff5146b74f" ? (
              <span>
                <Tooltip title="Delete Invoice">
                  <DeleteOutlined onClick={() => handleConfirmModal(record)} />
                </Tooltip>
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              {clientRole == "05857c29-5129-4aed-ae5e-30f0155fdbf3" ||
              clientRole == "33e2bba8-73ef-44f0-a6ab-60ff5146b74f" ? (
                <button onClick={handleCreateModal}>Create Invoice </button>
              ) : (
                ""
              )}
            </div>
            <AdminDataTable
              data={data}
              columns={columns}
              pagination={{ ...paginationConfig, onChange: handlePageChange }}
            />
          </Col>
        </Row>
      </div>

      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Invoice?"}
          description={"Are you sure you want to delete this invoice?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}

      {createInvoice && (
        <CreateInvoiceModal
          handleClose={handleCloseCreateModal}
          addInvoice={handleCreateInvoice}
        />
      )}

      {loading && <DataLoader />}
    </>
  );
}
export default InvoiceManagement;
