import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { PriorityContext } from "../../../context/PriorityContext";
import { StatusContext } from "../../../context/StatusContext";
import { BillingTypeContext } from "../../../context/BillingTypeContext";
import { ProjectContext } from "../../../context/ProjectContext";
import TextArea from "antd/lib/input/TextArea";

const CreateTaskModal = ({ handleClose, addTask, project_uuid }) => {
  const { getPriorityList, priorityList } = useContext(PriorityContext);
  const { getBillingTypeList, billingtypeList } =
    useContext(BillingTypeContext);
  const { getStatusList, statusList, loading } = useContext(StatusContext);
  const { allProjectList, getAllProjectList } = useContext(ProjectContext);
  const [clientUuid] = useState(localStorage.getItem("crm_user_uuid"));
  const [companyUuid] = useState(localStorage.getItem("crm_company_uuid"));

  const [form] = Form.useForm();

  useEffect(() => {
    getPriorityList();
    getBillingTypeList();
    getStatusList("?type=task");
    let clientQuery = "";
    let companyQuery = "";
    if (clientUuid) {
      clientQuery = `?client_uuid=${clientUuid}`;
    }

    if (companyUuid) {
      companyQuery = `/companies/${companyUuid}`;
    }

    getAllProjectList(clientQuery, companyQuery);

    if (project_uuid) {
      form.setFieldsValue({
        project_uuid: project_uuid,
      });
    }
  }, []);

  const handleFormSubmit = () => {
    const data = form.getFieldsValue();
    data.hours ? data.hours : (data.hours = 0);
    addTask(data);
    handleClose();
  };

  return (
    <Modal
      visible={true}
      title={<span>Create Task</span>}
      centered
      onCancel={handleClose}
      footer={null}
      width={750}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
              <Col xs={24} sm={8}>
                <label className="title">
                  Title<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please Input your Title!",
                    },
                  ]}
                >
                  <Input name="title" type="text" placeholder="Title..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  Project <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"project_uuid"}
                  rules={[
                    { required: true, message: "Please select your Project!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="project_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {allProjectList?.map((project, index) => (
                      <Option value={project.uuid} key={index}>
                        {project.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  Priority <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"priority_uuid"}
                  rules={[
                    { required: true, message: "Please select your Priority!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="priority_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {priorityList?.map((priority, index) => (
                      <Option value={priority.uuid} key={index}>
                        {priority.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  Status <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"status_uuid"}
                  rules={[
                    { required: true, message: "Please select your Status!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="status_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {statusList?.map((status, index) => (
                      <Option value={status.uuid} key={index}>
                        {status?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  Deadline<span style={{ color: "red" }}></span>
                </label>
                <Form.Item name="deadline">
                  <Input name="deadline" type="date" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  Cost<span style={{ color: "red" }}></span>
                </label>
                <Form.Item name="cost">
                  <Input name="cost" type="number" placeholder="Cost..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <label className="title">
                  Billing Type <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item name={"billing_type_uuid"}>
                  <Select
                    placeholder=""
                    name="billing_type_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {billingtypeList?.map((billing_type, index) => (
                      <Option value={billing_type.uuid} key={index}>
                        {billing_type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <label className="title">
                  Hours<span style={{ color: "red" }}></span>
                </label>
                <Form.Item name="hours">
                  <Input name="hours" type="number" placeholder="Hours..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <label className="title">
                  Description<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item name="description">
                  <TextArea
                    name="description"
                    type="text"
                    placeholder="Description..."
                    rows={4}
                  ></TextArea>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <Form.Item name="is_billable" valuePropName="checked">
                  <Checkbox checked={true}>Is Billable?</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Submit
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateTaskModal;
