import React from "react";

// Utility function to convert date format
const ModalDateFormat = (inputDateString) => {
  const dateObject = new Date(inputDateString);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export default ModalDateFormat;
