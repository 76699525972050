import React, { createContext, useState } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";

export const PriorityContext = createContext();

export const PriorityContextProvider = ({ children }) => {
    const [priorityList, setPriorityList] = useState([]);
    const getPriorityList = async () => {
        try {
            const res = await axios.get(
              `${process.env.REACT_APP_BACKEND_ENV}/priorities`,
              BEARER_TOKEN_HEADERS
            );
            setPriorityList(res?.data);
          } catch (err) {
            errorToast(err);
          }
    }

    return (
        <PriorityContext.Provider
        value={{
            getPriorityList,
            priorityList,
        }}
        >
          {children}
        </PriorityContext.Provider>
    )
}