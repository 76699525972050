import React, { useState, createContext, useContext } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";

export const TaskContext = createContext();

export const TaskContextProvider = ({ children }) => {
  const [taskList, setTaskList] = useState([]);
  const [projectTaskList, setProjectTaskList] = useState([]);
  const [allTaskList, setAllTaskList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [singleTaskLoader, setSingleTaskLoader] = useState(false);
  const [singleTask, setSingleTask] = useState(null);

  const getTaskList = async (page, paginationStatus, clientQuery = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}${clientQuery}/tasks?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      setTaskList(res?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getAllTaskList = async (page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/tasks?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      setAllTaskList(res?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const registerTask = async (
    uuid,
    companyQuery = "",
    data,
    page,
    paginationStatus
  ) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/tasks`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message ? res.data.message : "Task added successfully"
      );
      getTaskList(page, paginationStatus, companyQuery);
      if (uuid) {
        getProjectTaskList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const deleteTask = async (
    uuid,
    companyQuery = "",
    id,
    page,
    paginationStatus
  ) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/tasks/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getTaskList(page, paginationStatus, companyQuery);
      if (uuid) {
        getProjectTaskList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const updateTask = async (
    uuid,
    companyQuery = "",
    data,
    id,
    page,
    paginationStatus
  ) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/tasks/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      toast.success(
        res?.data?.message ? res.data.message : "Task Updated successfully"
      );
      getTaskList(page, paginationStatus, companyQuery);
      if (uuid) {
        getProjectTaskList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setLoading(false);
    }
  };

  const getSingleTask = async (id) => {
    setSingleTaskLoader(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/tasks/${id}`,
        BEARER_TOKEN_HEADERS
      );
      setSingleTask(res?.data);
      console.log("Task list result", res?.data);
      setSingleTaskLoader(false);
    } catch (err) {
      errorToast(err);
      setSingleTaskLoader(false);
    }
  };

  const getProjectTaskList = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/projects/${id}/tasks?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      setProjectTaskList(res?.data);
      console.log(res?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  return (
    <TaskContext.Provider
      value={{
        getTaskList,
        registerTask,
        deleteTask,
        updateTask,
        getSingleTask,
        getAllTaskList,
        getProjectTaskList,
        projectTaskList,
        allTaskList,
        taskList,
        loading,
        data: singleTask,
        singleTaskLoader,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};
