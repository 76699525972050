// import { useState } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faSignOutAlt,
  faProjectDiagram,
  faFileInvoice,
  faTasks,
  faMedkit,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import Logobox from "../../../logoBox";

const { SubMenu } = Menu;
function Companynav({ page, logout }) {
  const Logout = () => {
    logout();
  };
  return (
    <>
      <div className="brand">
        <NavLink to="/">
          <Logobox DashboardLogo="DashboardLogo" />
        </NavLink>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/admin/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faChartLine} />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/admin/projects">
            <span
              className="icon"
              style={{
                background: page === "projects" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faProjectDiagram} />
            </span>
            <span className="label">Projects</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/admin/tasks">
            <span
              className="icon"
              style={{
                background: page === "tasks" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faTasks} />
            </span>
            <span className="label">Tasks</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/admin/clients">
            <span
              className="icon"
              style={{
                background: page === "clients" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faUsers} />
            </span>
            <span className="label">Clients</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/admin/invoice">
            <span
              className="icon"
              style={{
                background: page === "invoice" ? "#2992d4" : "",
              }}
            >
              <FontAwesomeIcon icon={faFileInvoice} />
            </span>
            <span className="label">Invoices</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <span className="unlink" onClick={Logout}>
            <span className="icon">
              <FontAwesomeIcon icon={faSignOutAlt} />
            </span>
            <span className="label">Logout</span>
          </span>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Companynav;
