import React, { useState, createContext } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";

export const CompanyContext = createContext();

export const CompanyContextProvider = ({ children }) => {
  const [companyList, setCompanyList] = useState([]);
  const [allCompanyList, setAllCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [singleCompanyLoader, setSingleCompanyLoader] = useState(false);
  const [singleCompany, setSingleCompany] = useState(null);

  const getCompanyList = async (page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/companies?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      setCompanyList(res?.data);
      setLoading(false);
      console.log(companyList);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getAllCompanyList = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/companies`,
        BEARER_TOKEN_HEADERS
      );
      setAllCompanyList(res?.data);
      setLoading(false);
      console.log(companyList);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const registerCompany = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/companies`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message ? res.data.message : "Company added successfully"
      );
      getCompanyList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const deleteCompany = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/companies/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getCompanyList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getSingleCompany = async (id) => {
    setSingleCompanyLoader(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/companies/${id}`,
        BEARER_TOKEN_HEADERS
      );
      setSingleCompany(res?.data);
      console.log("Client list result", res?.data);
      setSingleCompanyLoader(false);
    } catch (err) {
      errorToast(err);
      setSingleCompanyLoader(false);
    }
  };

  const updateCompany = async (data, id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/companies/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      toast.success(
        res?.data?.message ? res.data.message : "Company Updated successfully"
      );
      getCompanyList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setLoading(false);
    }
  };

  return (
    <CompanyContext.Provider
      value={{
        getSingleCompany,
        registerCompany,
        getCompanyList,
        getAllCompanyList,
        updateCompany,
        data: singleCompany,
        singleCompanyLoader,
        companyList,
        allCompanyList,
        loading,
        deleteCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
