import React, { createContext, useState } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";
export const ClientContext = createContext();

export const ClientContextProvider = ({ children }) => {
  const [usersList, setUsersList] = useState([]);
  const [companyUsersList, setCompanyUsersList] = useState([]);
  const [allUsersList, setAllUsersList] = useState([]);
  const [singleClient, setSingleClient] = useState(null);
  const [allActiveUsersList, setAllActiveUsersList] = useState([]);
  const [singleClientLoader, setSingleClientLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const getClientList = async (page, paginationStatus, companyQuery = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}${companyQuery}/clients?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      setUsersList(res?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getAllClientList = async (companyQuery = "") => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}${companyQuery}/clients`,
        BEARER_TOKEN_HEADERS
      );
      setAllUsersList(res?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const registerClient = async (
    data,
    uuid,
    companyQuery = "",
    page,
    paginationStatus
  ) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/clients`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message ? res.data.message : "Client added successfully"
      );
      getClientList(page, paginationStatus, companyQuery);
      if (uuid) {
        getCompanyClientList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getSingleClient = async (id) => {
    setSingleClientLoader(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/clients/${id}`,
        BEARER_TOKEN_HEADERS
      );
      setSingleClient(res?.data);
      console.log("Client list result", res?.data);
      setSingleClientLoader(false);
    } catch (err) {
      errorToast(err);
      setSingleClientLoader(false);
    }
  };

  const updateClient = async (
    data,
    uuid,
    companyQuery = "",
    id,
    page,
    paginationStatus
  ) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/clients/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      toast.success(
        res?.data?.message ? res.data.message : "Client Updated successfully"
      );
      getClientList(page, paginationStatus, companyQuery);
      if (uuid) {
        getCompanyClientList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
      setLoading(false);
    }
  };

  const deleteUser = async (
    id,
    uuid,
    companyQuery = "",
    page,
    paginationStatus
  ) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/clients/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getClientList(page, paginationStatus, companyQuery);
      if (uuid) {
        getCompanyClientList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getCompanyClientList = async (uuid, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/companies/${uuid}/clients?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      setCompanyUsersList(res?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  return (
    <ClientContext.Provider
      value={{
        getSingleClient,
        getCompanyClientList,
        getClientList,
        registerClient,
        deleteUser,
        updateClient,
        getAllClientList,
        companyUsersList,
        allUsersList,
        data: singleClient,
        singleClientLoader,
        loading,
        usersList,
        allActiveUsersList,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
