import React, { createContext, useState } from "react";
import axios from "axios";
import { errorToast } from "../utils";
import { BEARER_TOKEN_HEADERS } from "../utils";

export const BillingFrequencyContext = createContext();

export const BillingFrequencyContextProvider = ({ children }) => {
    const [billingfrequencyLists, setBillingFrequencyLists] = useState([]);

    const getBillingFrequencyList = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_ENV}/billing_frequencies`,
            BEARER_TOKEN_HEADERS
          );
          
          setBillingFrequencyLists(res.data);
          console.log(res?.data);
        } catch (err) {
          console.log(err?.response?.data?.message);
          errorToast(err);
        }
      };

      return (
        <BillingFrequencyContext.Provider
          value={{
            getBillingFrequencyList,
            billingfrequencyLists,
          }}
        >
          {children}
        </BillingFrequencyContext.Provider>
      );
}