import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Radio, Tooltip } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import {
  ConfirmationModal,
  CreateClientModal,
  UpdateClientModal,
} from "../../components/Modals/index";
import DataLoader from "../../components/dataLoader";
import { ClientContext } from "../../context/ClientContext";
import { getFormattedDateTime, getFormattedData } from "../../utils";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
const { Title } = Typography;

function GetClientManagement(props) {
  const {
    getCompanyClientList,
    registerClient,
    updateClient,
    loading,
    companyUsersList,
    deleteUser,
  } = useContext(ClientContext);
  const [selectedData, setselectedData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [companyUuid] = useState(localStorage.getItem("crm_company_uuid"));
  const [companyQuery, setCompanyQuery] = useState("");
  const [clientRole, setClientRole] = useState(null);
  // const [filteredUsers, setFilteredUsers] = useState([]);
  // const [filterStatus, setFilterStatus] = useState("all");
  const [page, setPage] = useState(1);
  const { meta, data } = companyUsersList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };

  console.log(companyUsersList);

  useEffect(() => {
    setClientRole(localStorage.getItem("crm_role"));
  }, []);

  useEffect(() => {
    let companyQuery = "";
    if (companyUuid) {
      companyQuery = `/companies/${companyUuid}`;
      setCompanyQuery(companyQuery);
    }
    getCompanyClientList(props.uuid, page, true);
  }, [page]);

  // useEffect(() => {
  //   const temp = usersList?.data?.filter((user) =>
  //     filterStatus === "active"
  //       ? user?.is_active
  //       : filterStatus === "inactive"
  //       ? !user?.is_active
  //       : user
  //   );
  //   console.log("filteredUsers", temp);
  //   setFilteredUsers(temp);
  // }, [filterStatus, data]);

  const handleCreateModal = () => {
    setCreateUserModal(true);
  };

  const handleCreateClient = (data) => {
    registerClient(data, props.uuid, companyQuery, page, true);
  };

  const handleCloseCreateModal = () => {
    setCreateUserModal(false);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "fullname",
      key: "fullname",
      render: (data, value) => {
        return (
          <span>{`${value?.user?.firstname} ${value?.user?.lastname}`}</span>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (data, value) => {
        return <span>{value?.user?.email}</span>;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (data, value) => {
        return <span>{value?.phone}</span>;
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (data, value) => {
        return <span>{value?.country?.name}</span>;
      },
    },
    {
      title: "Activated",
      dataIndex: "is_active",
      key: "user",
      render: (data, value) => {
        return <span>{value?.user?.is_active ? "Active" : "Inactive"}</span>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        const { slug, uuid } = record;
        return (
          <div className="actions">
            <Link to={`../client/${uuid}`} className="preview">
              <Tooltip title="View client">
                <FontAwesomeIcon icon={faEye} />
              </Tooltip>
            </Link>
            <span className="update" onClick={() => handleUpdateModal(record)}>
              <Tooltip title="Update Client">
                <EditOutlined />
              </Tooltip>
            </span>
            <span>
              <Tooltip title="Delete Client">
                <DeleteOutlined onClick={() => handleConfirmModal(record)} />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  // const handleFilterChange = (e) => {
  //   setFilterStatus(e.target.value);
  // };
  //HANDLERS

  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteData = (id) => {
    deleteUser(id, props.uuid, companyQuery, page, true);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  const handleUpdateModal = (data) => {
    setselectedData(data);
    setShowUpdateModal(true);
  };

  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };

  const handleUpdateData = (data, id) => {
    updateClient(data, props.uuid, companyQuery, id, page, true);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          {props.title ? (
            <>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <h3>{props.title}</h3>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                {clientRole == "05857c29-5129-4aed-ae5e-30f0155fdbf3" ||
                clientRole == "33e2bba8-73ef-44f0-a6ab-60ff5146b74f" ? (
                  <>
                    <div className="add-btn">
                      <button onClick={handleCreateModal}>Create Client</button>
                    </div>
                  </>
                ) : (
                  <div className="mb-24"></div>
                )}
              </Col>
            </>
          ) : (
            <div className="mb-24"></div>
          )}

          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <AdminDataTable
              data={data}
              columns={columns}
              pagination={{ ...paginationConfig, onChange: handlePageChange }}
            />
          </Col>
        </Row>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Client?"}
          description={"Are you sure you want to delete this Client?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}

      {showUpdateModal && (
        <UpdateClientModal
          handleClose={handleUpdateModalClose}
          selectedData={selectedData}
          updateData={handleUpdateData}
        />
      )}
      {createUserModal && (
        <CreateClientModal
          handleClose={handleCloseCreateModal}
          addUser={handleCreateClient}
        />
      )}
      {loading && <DataLoader />}
    </>
  );
}

export default GetClientManagement;
