import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Loader/index";
import {
  Row,
  Col,
  Card,
  Typography,
  Collapse,
  Tabs,
  Modal,
  Space,
  Avatar,
  Tooltip,
} from "antd";
import { ClientContext } from "../../../context/ClientContext";
import {
  GetInvoiceManagement,
  GetProjectManagement,
  GetSubscriptionManagement,
} from "../../../modules/admin";

const Client = () => {
  const { TabPane } = Tabs;
  const { uuid } = useParams();
  const { Title, Paragraph } = Typography;
  const { getSingleClient, data, singleClientLoader } =
    useContext(ClientContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [largeImageUrl, setLargeImageUrl] = useState("");

  console.log("This is my data :", data);

  const handlePhotoClick = (photoUrl) => {
    setModalVisible(true);
    setLargeImageUrl(photoUrl);
  };

  useEffect(() => {
    console.log(uuid);
    getSingleClient(uuid);
  }, []);

  if (singleClientLoader) {
    return <Loader />;
  } else {
    const tab1Sec = () => (
      <>
        <Row gutter={[6, 0]}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <div className="profile-photo centered-content">
              <Space direction="vertical" align="center" size={100}>
                <Space wrap size={100}>
                  {data?.user?.photo_thumb !== null &&
                  data?.user?.photo_thumb !== "" ? (
                    <Avatar
                      size={150}
                      src={data?.user?.photo_thumb}
                      onClick={() => handlePhotoClick(data?.user?.photo)}
                    />
                  ) : (
                    <Avatar size={150} icon={<UserOutlined />} />
                  )}
                </Space>
              </Space>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={18} xl={18}>
            <div className="profile-details">
              <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                  <h5 className="detail-title">User Name</h5>
                  <p className="detail-description">
                    {data?.user?.firstname} {data?.user?.lastname}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                  <h5 className="detail-title">Email</h5>
                  <p className="detail-description">{data?.user?.email}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                  <h5 className="detail-title">Phone</h5>
                  <p className="detail-description">{data?.phone}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                  <h5 className="detail-title">Country</h5>
                  <p className="detail-description">{data?.country?.name}</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {modalVisible && (
          <div className="layout-content">
            <div className="mb-24">
              <Card bordered={false} className="criclebox">
                {/* Rest of the code */}
              </Card>
            </div>

            <Modal
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              footer={null}
            >
              <img
                src={largeImageUrl}
                alt="Large Avatar"
                style={{ width: "100%" }}
              />
            </Modal>
          </div>
        )}
      </>
    );
    const noData = (message) => <p className="no-data">{message}</p>;

    return (
      <div className="layout-content">
        <div className="mb-24">
          <Card bordered={false} className="criclebox">
            <Title level={5}>{data?.headline}</Title>
            <Paragraph className="lastweek" style={{ marginBottom: 5 }}>
              {data?.brief}
            </Paragraph>
            <Tabs defaultActiveKey="1" style={{ marginTop: 20 }}>
              <TabPane tab="Detail" key="1">
                {tab1Sec()}
              </TabPane>
            </Tabs>
          </Card>
        </div>
        <div className="mb-24">
          <Card bordered={false} className="criclebox">
            <GetProjectManagement title="Projects" uuid={uuid} />
          </Card>
        </div>

        <div className="mb-24">
          <Card bordered={false} className="criclebox">
            <GetSubscriptionManagement title="Subscriptions" uuid={uuid} />
          </Card>
        </div>

        <div className="mb-24">
          <Card bordered={false} className="criclebox">
            <GetInvoiceManagement
              title="Invoices"
              uuid={uuid}
              generate_invoice_btn={true}
            />
          </Card>
        </div>
      </div>
    );
  }
};

export default Client;
