import React from "react";
import "./styles.scss";
import crmLogo from '../../assets/brand/crmLogo.png';

const Logobox = ({ size , SignInLogo , DashboardLogo }) => {
  return (
    <div className={`logobox ${size === "lg" ? "large" : ""}`}>
      <img className={SignInLogo ? SignInLogo : DashboardLogo} src={crmLogo}/>
    </div>
  );
};

export default Logobox;
