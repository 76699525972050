import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { CountryContext } from "../../../context/CountryContext";

const UpdateCompanyModal = ({ handleClose, selectedData, updateData }) => {
  const { allCountryList, getAllCountryList } = useContext(CountryContext);
  const [file, setFile] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    getAllCountryList();
  }, []);

  useEffect(() => {
    console.log(allCountryList);
  }, [allCountryList]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    console.log("Selected Company", selectedData);
    form.setFieldsValue({
      firstname: selectedData.user.firstname,
      lastname: selectedData.user.lastname,
      name: selectedData.name,
      email: selectedData.email,
      password: "",
      phone: selectedData.phone,
      address: selectedData.address,
      city: selectedData.city,
      state: selectedData.state,
      zip: selectedData.zip,
      country_uuid: selectedData.country.uuid,
      is_active: selectedData.user.is_active,
      // newsletter:selectedData.user.newsletter,
    });
  }, []);

  const handleFormSubmit = () => {
    const data = form.getFieldsValue();

    const formData = new FormData();
    if (file != null) {
      formData.append("logo", file);
    }
    formData.append("firstname", data.firstname);
    formData.append("lastname", data.lastname);
    if (data.password != "") {
      formData.append("password", data.password);
    }
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("address", data.address);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("zip", data.zip);
    formData.append("country_uuid", data.country_uuid);
    formData.append("is_active", data.is_active ? 1 : 0);
    formData.append("user_uuid", selectedData.user.uuid);
    console.log(formData);
    updateData(formData, selectedData.uuid);
    handleClose();
  };

  return (
    <Modal
      visible={true}
      title={<span>Update Company</span>}
      centered
      onCancel={handleClose}
      footer={null}
      width={820}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
              <Col xs={24} sm={24}>
                <label className="title">
                  Logo<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item name="logo">
                  <Input name="logo" type="file" onChange={handleFileChange} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <label className="title">
                  First Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name!",
                    },
                  ]}
                >
                  <Input name="firstname" placeholder="First Name..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <label className="title">
                  Last Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Last Name!",
                    },
                  ]}
                >
                  <Input name="lastname" placeholder="Last Name..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <label className="title">
                  Company Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Company name!",
                    },
                  ]}
                >
                  <Input name="name" placeholder="Company Name..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <label className="title">
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"email"}
                  rules={[
                    { type: "email", message: "Enter a valid email" },
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input name="email" placeholder="Email..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  Password<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item name={"password"}>
                  <Input name="password" placeholder="Password..." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  Phone<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"phone"}
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    name="phone"
                    autoComplete="phone"
                    placeholder="Phone..."
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  Address<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"address"}
                  rules={[
                    { required: true, message: "Please input your address!" },
                  ]}
                >
                  <Input
                    name="address"
                    autoComplete="address"
                    placeholder="Address..."
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  City<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"city"}
                  rules={[
                    { required: true, message: "Please input your city!" },
                  ]}
                >
                  <Input
                    name="city"
                    autoComplete="city"
                    placeholder="City..."
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  State<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"state"}
                  rules={[
                    { required: true, message: "Please input your state!" },
                  ]}
                >
                  <Input
                    name="state"
                    autoComplete="state"
                    placeholder="State..."
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <label className="title">
                  Zip Code<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"zip"}
                  rules={[
                    { required: true, message: "Please input your zip code!" },
                  ]}
                >
                  <Input
                    type="number"
                    name="zip"
                    autoComplete="zip"
                    placeholder="Zip Code..."
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Country <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"country_uuid"}
                  rules={[
                    { required: true, message: "Please select your Country!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="country_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {allCountryList?.map((country, index) => (
                      <Option value={country.uuid} key={index}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <Form.Item name="is_active" valuePropName="checked">
                  <Checkbox checked={true}>Is active?</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Update
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UpdateCompanyModal;
