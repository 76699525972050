import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Form, Input, Button, Tooltip } from "antd";
import { FilterFilled, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ConfirmationModal } from "../../components/Modals/index";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { UserContext } from "../../context/UserContext";
import { CreateUserModal, UpdateUserModal } from "../../components/Modals";
import DataLoader from "../../components/dataLoader";
import { getFormattedData } from "../../utils";
import FilterDrawer from "../../components/filterDrawer";
const { Title } = Typography;
import { Slider, Switch } from 'antd';

function UserManagement() {
  const { getClientList, clientList, registerClient, updateClient, loading, deleteClient } =
    useContext(UserContext);
  const [selectedData, setselectedData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [createClientModal, setCreateClientModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [page, setPage] = useState(1);
  const { meta, data } = clientList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };
  const [form] = Form.useForm();
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    setFilterOpen(false);
    setFilteredData(credentials);
  };
  const handleResetForm = () => {
    form.resetFields();
    setFilteredData(null);
    form.setFieldsValue({
      email: undefined,
    });
    setFilterOpen(false);
  };

  useEffect(() => {
    let filterQuery = "";
    if (filteredData) {
      Object.keys(filteredData).forEach(function (key) {
        if (filteredData[key] && key !== "date") {
          filterQuery += `&${key}=${filteredData[key]}`;
        }
      });
    }
    getClientList(page, true, filterQuery);
  }, [page, filteredData]);

  //Handlers

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };

  const handleCreateModal = () => {
    setCreateClientModal(true);
  };

  const handleCloseCreateModal = () => {
    setCreateClientModal(false);
  };

  const handleCreateClient = (data) => {
    registerClient(data, page, true);
  };
  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  /*
  This Function is Comment and not delete this function 
  */

  // const formatValue = (value) => {
  //   if (value >= 1000) {
  //     return `${Math.floor(value / 1000)}k`;
  //   }
  //    else {
  //     return value;
  //   }
  // };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Name",
      dataIndex: "user",
      key: "fullname",
      render: (data, value) => {
        return (
          <span>{value ? `${value.firstname} ${value.lastname}` : `-`}</span>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (data, value) => <span>{getFormattedData(value?.role?.name)}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (data, value) => <span>{getFormattedData(value?.email)}</span>,
    },
    {
      title: "User status",
      dataIndex: "is_active",
      key: "is_active",
      render: (data, value) => (
        <span>{value.is_active ? "Active" : "Inactive"}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        const { slug, uuid } = record;
        return (
          <div className="actions">
            <Link to={`user/${uuid}`} className="preview">
              <Tooltip title="View User">
                <FontAwesomeIcon icon={faEye} />
              </Tooltip>
            </Link>

            <span className="update" onClick={() => handleUpdateModal(record)}>
              <Tooltip title="Update User">
                <EditOutlined />
              </Tooltip>
            </span>


            <span>
              <Tooltip title="Delete User">
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => handleConfirmModal(record)}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  const drawerSec = () => (
    <>
      {isFilterOpen && (
        <Form
          form={form}
          className="form"
          initialValues={filteredData}
          onFinish={handleFormFinish}
        >
          <label className="form-lbl">Name / Email:</label>
          <Form.Item name={"email"}>
            <Input type="text" />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              className="form-btn"
              style={{ minWidth: "100%" }}
            >
              Apply Filters
            </Button>
            <span className="form-rm-btn" onClick={handleResetForm}>
              Reset Filters
            </span>
          </Form.Item>
        </Form>
      )}
    </>
  );

  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleUpdateModal = (data) => {
    setselectedData(data);
    setShowUpdateModal(true);
  };
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };

  const handleUpdateData = (data, id) => {
    updateClient(data, id, page, true);
  };


  const handleDeleteData = (id) => {
    deleteClient(id, page, true);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <button onClick={handleCreateModal}>Create User</button>
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <Title level={5}>Users</Title>
                <Button className="filter-btn" onClick={filterHandler}>
                  <FilterFilled />
                  Filter
                </Button>
              </div>
              <AdminDataTable
                data={data}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>

          {/*
          This Slider is not delete after work this slider ok
          */}

          {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
          <Slider
          min={1}
          max={500000}
          tipFormatter={formatValue}
        />
          </Col> */}
        </Row>
      </div>
      {createClientModal && (
        <CreateUserModal
          handleClose={handleCloseCreateModal}
          addClient={handleCreateClient}
        />
      )}
      
      {showUpdateModal && (
        <UpdateUserModal
          handleClose={handleUpdateModalClose}
          selectedData={selectedData}
          updateData={handleUpdateData}
        />
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete User?"}
          description={"Are you sure you want to delete this User?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
      {loading && <DataLoader />}
    </>
  );
}

export default UserManagement;
