import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ErrorPage from "../components/404/index.jsx";
// PRIVATE ROUTES
import { AdminPrivateRoute } from "../utils/routing/index";
import {
  AdminDashboard,
  RoleManagement,
  ClientManagement,
  CountriesManagement,
  UserManagement,
  SignIn,
  CompanyManagement,
  ProjectManagement,
  TaskManagement,
  StatusManagement,
  ProductTypeManagement,
  ProductManagement,
  SubscriptionManagement,
  InvoiceManagement,
} from "../modules/admin/";
import {
  User,
  Client,
  Company,
  Project,
  Task,
  Subscription,
  Product,
  Invoice,
} from "../components/moduleDetail";

const Routes = () => {
  return (
    <>
      <Router>
        <ToastContainer autoClose={3000} />
        <Switch>
          {/*********** PUBLIC ROUTES **************/}
          <Route exact path="/" component={SignIn} />
          {/*********** ADMIN ROUTES **************/}
          <AdminPrivateRoute
            exact
            path="/admin/dashboard"
            component={AdminDashboard}
          />
          <AdminPrivateRoute
            exact
            path="/admin/countries"
            component={CountriesManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/roles"
            component={RoleManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/clients"
            component={ClientManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/client/:uuid"
            component={Client}
          />
          <AdminPrivateRoute
            exact
            path="/admin/users"
            component={UserManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/user/:userId"
            component={User}
          />
          <AdminPrivateRoute
            exact
            path="/admin/companies"
            component={CompanyManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/company/:uuid"
            component={Company}
          />
          <AdminPrivateRoute
            exact
            path="/admin/projects"
            component={ProjectManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/project/:uuid"
            component={Project}
          />
          <AdminPrivateRoute
            exact
            path="/admin/tasks"
            component={TaskManagement}
          />
          <AdminPrivateRoute exact path="/admin/task/:uuid" component={Task} />
          <AdminPrivateRoute
            exact
            path="/admin/product-type"
            component={ProductTypeManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/product"
            component={ProductManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/product/:uuid"
            component={Product}
          />
          <AdminPrivateRoute
            exact
            path="/admin/subscription"
            component={SubscriptionManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/subscription/:uuid"
            component={Subscription}
          />
          <AdminPrivateRoute
            exact
            path="/admin/statuses"
            component={StatusManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/invoice"
            component={InvoiceManagement}
          />
          <AdminPrivateRoute
            exact
            path="/admin/invoice/:uuid"
            component={Invoice}
          />
          {/* 404 PAGE ROUTE */}
          <Route path="*" component={ErrorPage} />
        </Switch>
      </Router>
    </>
  );
};
export default Routes;
