import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useState, useContext, useEffect } from "react";

const UpdateProductTypeModal = ({ handleClose, selectedData, updateData }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: selectedData.name,
    });
  }, []);

  const handleFormSubmit = () => {
    const data = form.getFieldsValue();

    updateData(data, selectedData.uuid);
    handleClose();
  };

  return (
    <Modal
      visible={true}
      title={<span>Update Product Type</span>}
      centered
      onCancel={handleClose}
      footer={null}
      width={450}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
              <Col xs={24} sm={24}>
                <label className="title">
                  Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please Input your Product Type Name!",
                    },
                  ]}
                >
                  <Input name="name" type="text" placeholder="Name..." />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Update
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UpdateProductTypeModal;
