import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Tooltip } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { RolesContext } from "../../context/RolesContext";
import {
  CreateRoleModal,
  UpdateRoleModal,
  ConfirmationModal,
} from "../../components/Modals/index";
import DataLoader from "../../components/dataLoader";

const { Title } = Typography;

function RoleManagement() {
  const {
    getSingleRole,
    rolesList,
    singleRole,
    getRolesList,
    createRole,
    updateRole,
    deleteRole,
    loading,
  } = useContext(RolesContext);

  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [page, setPage] = useState(1);
  const { meta, data } = rolesList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };

  useEffect(() => {
    getRolesList(page, true, 10);
  }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data, value) => {
        return <span>{data}</span>;
      },
    },

    {
      title: "Action",
      key: "action",
      render: (data, record) => {
        return (
          <div className="actions">
            <span className="update" onClick={() => handleUpdateModal(record)}>
              <Tooltip title="Update Role">
                <EditOutlined />
              </Tooltip>
            </span>
            <span style={{ opacity: data.is_default ? 0.3 : 1 }}>
              {data.is_default ? (
                <DeleteOutlined style={{ cursor: "auto" }} />
              ) : (
                <Tooltip title="Delete Role">
                  <DeleteOutlined onClick={() => handleConfirmModal(record)} />
                </Tooltip>
              )}
            </span>
          </div>
        );
      },
    },
  ];

  //HANDLERS
  const handleCreateModal = () => {
    setShowCreateRoleModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateRoleModal(false);
  };

  const handleCreateRoleType = (name) => {
    createRole({
      name,
    });
  };
  const handleUpdateModal = (role) => {
    setSelectedRole(role);
    setShowUpdateModal(true);
  };
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };
  const handleUpdateRole = (data, id) => {
    updateRole(data, id);
  };

  const handleConfirmModal = (role) => {
    setSelectedRole(role);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteRole = (id) => {
    deleteRole(id);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <button onClick={handleCreateModal}>Create Role</button>
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody "
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <div>
                  <Title level={5}>Roles</Title>
                  <Paragraph className="lastweek"></Paragraph>
                </div>
                {/* <div className="ant-filtertabs">
                  <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                    <Radio.Group defaultValue="all">
                      <Radio.Button value="all">ALL</Radio.Button>
                      <Radio.Button value="active">ACTIVE</Radio.Button>
                      <Radio.Button value="inactive">IN-ACTIVE</Radio.Button>
                    </Radio.Group>
                  </div>
                </div> */}
              </div>
              <AdminDataTable
                data={data}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      {showCreateRoleModal && (
        <CreateRoleModal
          handleClose={handleCloseCreateModal}
          addRoleType={handleCreateRoleType}
        />
      )}
      {showUpdateModal && (
        <UpdateRoleModal
          handleClose={handleUpdateModalClose}
          selectedRole={selectedRole}
          updateRole={handleUpdateRole}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Role?"}
          description={"Are you sure you want to delete this role?"}
          deleteHandler={handleDeleteRole}
          selectedData={selectedRole}
        />
      )}
      {loading && <DataLoader />}
    </>
  );
}

export default RoleManagement;
