import React, { createContext, useState } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";

export const SubscriptionItemContext = createContext();

export const SubscriptionItemContextProvider = ({ children }) => {
  const [allSubscriptionItemList, setAllSubscriptionItemList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllSubscriptionItemList = async (page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/subscription_items?page=${page}&paginate=${paginationStatus}`,
        BEARER_TOKEN_HEADERS
      );
      setAllSubscriptionItemList(res?.data);
      console.log(res?.data);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const registerSubscriptionItem = async (data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/subscription_items`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message
          ? res.data.message
          : "Subscription Item added successfully"
      );
      getAllSubscriptionItemList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const updateSubscriptionItem = async (data, page, paginationStatus, id) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/subscription_items/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message
          ? res.data.message
          : "Subscription Item Update successfully"
      );
      getAllSubscriptionItemList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const deleteSubscriptionItem = async (id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/subscription_items/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAllSubscriptionItemList(page, paginationStatus);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  return (
    <SubscriptionItemContext.Provider
      value={{
        getAllSubscriptionItemList,
        registerSubscriptionItem,
        deleteSubscriptionItem,
        allSubscriptionItemList,
        updateSubscriptionItem,
        loading,
      }}
    >
      {children}
    </SubscriptionItemContext.Provider>
  );
};
