import React, { createContext, useState } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";
import { toast } from "react-toastify";

export const SubscriptionContext = createContext();

export const SubscriptionContextProvider = ({ children }) => {
  const [allSubscriptionList, setAllSubscriptionList] = useState([]);
  const [allClientSubscriptionList, setAllClientSubscriptionList] = useState(
    []
  );
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [singleSubscriptionLoader, setSingleSubscriptionLoader] =
    useState(false);
  const [singleSubscription, setSingleSubscription] = useState(null);
  const [singleSubscriptionItems, setSingleSubscriptionItems] = useState(null);

  const getAllSubscriptionList = async (
    page,
    paginationStatus,
    clientQuery = ""
  ) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/subscriptions?page=${page}&paginate=${paginationStatus}${clientQuery}`,
        BEARER_TOKEN_HEADERS
      );
      setAllSubscriptionList(res?.data);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const getSubscriptionList = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/subscriptions`,
        BEARER_TOKEN_HEADERS
      );
      setSubscriptionList(res?.data);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  const registerSubscription = async (uuid, data, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/subscriptions`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message
          ? res.data.message
          : "Subscription added successfully"
      );
      getAllSubscriptionList(page, paginationStatus);
      if (uuid) {
        getAllClientSubscriptionList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const updateSubscription = async (uuid, data, page, paginationStatus, id) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENV}/subscriptions/${id}`,
        data,
        BEARER_TOKEN_HEADERS
      );
      console.log("res", res);
      toast.success(
        res?.data?.message
          ? res.data.message
          : "Subscription Update successfully"
      );
      getAllSubscriptionList(page, paginationStatus);
      if (uuid) {
        getAllClientSubscriptionList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const deleteSubscription = async (uuid, id, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_ENV}/subscriptions/${id}`,
        BEARER_TOKEN_HEADERS
      );
      toast.success(res?.data?.message);
      getAllSubscriptionList(page, paginationStatus);
      if (uuid) {
        getAllClientSubscriptionList(uuid, page, paginationStatus);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToast(err);
    }
  };

  const getSingleSubscription = async (id) => {
    setSingleSubscriptionLoader(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/subscriptions/${id}`,
        BEARER_TOKEN_HEADERS
      );
      setSingleSubscription(res?.data);
      console.log("Subscription result", res?.data);
      setSingleSubscriptionItems(res?.data?.subscription_items);
      setSingleSubscriptionLoader(false);
    } catch (err) {
      errorToast(err);
      setSingleSubscriptionLoader(false);
    }
  };

  const getAllClientSubscriptionList = async (uuid, page, paginationStatus) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/subscriptions?page=${page}&paginate=${paginationStatus}&client_uuid=${uuid}`,
        BEARER_TOKEN_HEADERS
      );
      setAllClientSubscriptionList(res?.data);
      setLoading(false);
    } catch (err) {
      errorToast(err);
      setLoading(false);
    }
  };

  return (
    <SubscriptionContext.Provider
      value={{
        getAllSubscriptionList,
        getAllClientSubscriptionList,
        getSubscriptionList,
        registerSubscription,
        deleteSubscription,
        allSubscriptionList,
        allClientSubscriptionList,
        subscriptionList,
        updateSubscription,
        getSingleSubscription,
        data: singleSubscription,
        subscriptionItems: singleSubscriptionItems,
        singleSubscriptionLoader,
        loading,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
