import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Loader/index";
import { Row, Col, Card, Typography, Collapse, Tabs, Modal, Space, Avatar, Tooltip } from "antd";
import AdminDataTable from "../../Layouts/sharedAdminLayout/partials/data-table";
import { UserContext } from "../../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const User = () => {
  const { TabPane } = Tabs;
  const { userId } = useParams();
  const { Title, Paragraph } = Typography;
  const { getSingleClient, data, singleClientLoader } =
    useContext(UserContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [largeImageUrl, setLargeImageUrl] = useState("");

  const handlePhotoClick = (photoUrl) => {
    setModalVisible(true);
    setLargeImageUrl(photoUrl);
  };
  useEffect(() => {
    getSingleClient(userId);
  }, []);
  // const { data } = singleClient;
  if (singleClientLoader) {
    return <Loader />;
  } else {
    const tab1Sec = () => (
      <>
        <Row gutter={[6, 0]}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <div className="profile-photo centered-content">
              <Space direction="vertical" align="center" size={100}>
                <Space wrap size={100}>
                  {data?.user?.photo_thumb !== null &&
                  data?.user?.photo_thumb !== "" ? (
                    <Avatar
                      size={150}
                      src={data?.user?.photo_thumb}
                      onClick={() => handlePhotoClick(data?.user?.photo)}
                    />
                  ) : (
                    <Avatar size={150} icon={<UserOutlined />} />
                  )}
                </Space>
              </Space>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={18} xl={18}>
            <div className="profile-details">
              <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Role</h5>
                  <p className="detail-description">
                    {data?.role?.name}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">User Name</h5>
                  <p className="detail-description">
                    {data?.firstname} {data?.lastname}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Email</h5>
                  <p className="detail-description">{data?.email}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Status</h5>
                  <p className="detail-description">{data?.is_active == 1 ? "active" : "Inactive"}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Created At</h5>
                  <p className="detail-description">
                    {data?.created_at}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">User Id</h5>
                  <p className="detail-description">
                    {data?.uuid}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {modalVisible && (
          <div className="layout-content">
            <div className="mb-24">
              <Card bordered={false} className="criclebox">
                {/* Rest of the code */}
              </Card>
            </div>

            <Modal
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              footer={null}
            >
              <img
                src={largeImageUrl}
                alt="Large Avatar"
                style={{ width: "100%" }}
              />
            </Modal>
          </div>
        )}
      </>
    );

    const noData = (message) => <p className="no-data">{message}</p>;
    return (
      <div className="layout-content">
        <div className="mb-24">
          <Card bordered={false} className="criclebox">
            <Title level={5}>{data?.headline}</Title>
            <Paragraph className="lastweek" style={{ marginBottom: 5 }}>
              {data?.brief}
            </Paragraph>
            <Tabs defaultActiveKey="1" style={{ marginTop: 20 }}>
              <TabPane tab="Detail" key="1">
                {tab1Sec()}
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    );
  }
};

export default User;
