import React from "react";
import {
  AuthContextProvider,
  UserContextProvider,
  CrewContextProvider,
  RolesContextProvider,
  ClientContextProvider,
  GenericContextProvider,
  DashboardContextProvider,
  CountryContextProvider,
  CompanyContextProvider,
  PriorityContextProvider,
  ProjectContextProvider,
  BillingTypeContextProvider,
  StatusContextProvider,
  SubscriptionTypeContextProvider,
  BillingFrequencyContextProvider,
  StatusTypeContextProvider,
  ProductTypeContextProvider,
  ProductContextProvider,
  SubscriptionContextProvider,
  SubscriptionItemContextProvider,
  InvoiceContextProvider,
} from "../../context";
import { TaskContextProvider } from "../../context/TaskContext";
export default function ContextWrapper({ children }) {
  return (
    <React.StrictMode>
      <AuthContextProvider>
        <CountryContextProvider>
          <UserContextProvider>
            <CrewContextProvider>
              <RolesContextProvider>
                <ClientContextProvider>
                  <CompanyContextProvider>
                    <PriorityContextProvider>
                      <BillingTypeContextProvider>
                        <StatusContextProvider>
                          <InvoiceContextProvider>
                            <StatusTypeContextProvider>
                              <ProjectContextProvider>
                                <TaskContextProvider>
                                  <ProductTypeContextProvider>
                                    <ProductContextProvider>
                                      <SubscriptionContextProvider>
                                        <SubscriptionItemContextProvider>
                                          <SubscriptionTypeContextProvider>
                                            <GenericContextProvider>
                                              <BillingFrequencyContextProvider>
                                                <DashboardContextProvider>
                                                  {children}
                                                </DashboardContextProvider>
                                              </BillingFrequencyContextProvider>
                                            </GenericContextProvider>
                                          </SubscriptionTypeContextProvider>
                                        </SubscriptionItemContextProvider>
                                      </SubscriptionContextProvider>
                                    </ProductContextProvider>
                                  </ProductTypeContextProvider>
                                </TaskContextProvider>
                              </ProjectContextProvider>
                            </StatusTypeContextProvider>
                          </InvoiceContextProvider>
                        </StatusContextProvider>
                      </BillingTypeContextProvider>
                    </PriorityContextProvider>
                  </CompanyContextProvider>
                </ClientContextProvider>
              </RolesContextProvider>
            </CrewContextProvider>
          </UserContextProvider>
        </CountryContextProvider>
      </AuthContextProvider>
    </React.StrictMode>
  );
}
