import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Form, Input, Button, Tooltip } from "antd";
import DataLoader from "../../components/dataLoader";
import { CountryContext } from "../../context/CountryContext";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { getFormattedData } from "../../utils";

function CountriesManagement(){

    const { getCountryList , loading , countryList } = useContext(CountryContext);
    const [page, setPage] = useState(1);
    const { meta, data } = countryList;
    const paginationConfig = {
        pageSize: meta?.per_page,
        total: meta?.total,
      };

    useEffect(() => {
        getCountryList(page , true);
    } , [page]);


    const handlePageChange = (page, pageSize) => {
        setPage(page);
      };


    const columns = [
        {
          title: "No",
          dataIndex: "No",
          key: "No",
          render: (data, value, i) => (
            <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
          ),
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          render: (data, value) => {
            return (
              <span>{value ? `${value.name}` : `-`}</span>
            );
          },
        },
        {
          title: "Iso3",
          dataIndex: "iso3",
          key: "iso3",
          render: (data, value) => <span>{getFormattedData(value?.iso3)}</span>,
        },
        {
          title: "Iso2",
          dataIndex: "iso2",
          key: "iso2",
          render: (data, value) => <span>{getFormattedData(value?.iso2)}</span>,
        },
        {
          title: "Phone Code",
          dataIndex: "phone code",
          key: "phone_code",
          render: (data, value) => (
            <span>{getFormattedData(value?.phone_code)}</span>
          ),
        },
      ];

    return (
        <>
         <div className="layout-content">
         <Row gutter={[24, 0]}>
         <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
         <AdminDataTable
                data={data}
                columns={columns}
                pagination={{ ...paginationConfig , onChange: handlePageChange}}
              />
            </Col>
            </Row>
            </div>
            {loading && <DataLoader />}
        </>
    );
}
export default CountriesManagement;