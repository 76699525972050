import React, { createContext, useState } from "react";
import axios from "axios";
import { errorToast } from "../utils";
import { BEARER_TOKEN_HEADERS } from "../utils";
import { toast } from "react-toastify";

export const SubscriptionTypeContext = createContext();

export const SubscriptionTypeContextProvider = ({ children }) => {
  const [subscriptionTypeList, setSubscriptionTypeList] = useState([]);

  const getSubscriptionTypeList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/subscription_types`,
        BEARER_TOKEN_HEADERS
      );

      setSubscriptionTypeList(res.data);
      console.log(res?.data);
    } catch (err) {
      console.log(err?.response?.data?.message);
      errorToast(err);
    }
  };

  return (
    <SubscriptionTypeContext.Provider
      value={{
        getSubscriptionTypeList,
        subscriptionTypeList,
      }}
    >
      {children}
    </SubscriptionTypeContext.Provider>
  );
};
