import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { RolesContext } from "../../../context/RolesContext";

const CreateUserModal = ({ handleClose, addClient }) => {
  const { rolesList, getRolesList } = useContext(RolesContext);
  const [photo, setPhoto] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    getRolesList();
  }, []);

  useEffect(() => {
    console.log(rolesList);
  }, [rolesList]);

  const handleAddThumb = (data) => {
    console.log("data", data);

    form.setFieldsValue({
      photo: data,
      photo_thumb: data,
    });
    setPhoto(data);
  };
  const handleFormSubmit = () => {
    const data = form.getFieldsValue();
    addClient(data);
    handleClose();
  };
  return (
    <Modal
      visible={true}
      title={<span>Create User</span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <label className="title">
                  First Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input name="firstname" placeholder="First name..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <label className="title">
                  Last Name<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"lastname"}
                  rules={[
                    { required: true, message: "Please input your last name!" },
                  ]}
                >
                  <Input name="lastname" placeholder="Last name..." />
                </Form.Item>
              </Col>
            </Row>
            <label className="title">
              Email<span style={{ color: "red" }}>*</span>
            </label>
            <Form.Item
              name={"email"}
              rules={[
                { type: "email", message: "Enter a valid email" },
                { required: true, message: "Please input your email!" },
              ]}
            >
              <Input name="email" placeholder="Email..." />
            </Form.Item>

            <Row gutter={"12"}>
              <Col xs={24} sm={24}>
                <label className="title">
                  Role id <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item
                  name={"role_uuid"}
                  rules={[
                    { required: true, message: "Please select your role id!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="role_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {rolesList?.map((role, index) => (
                      <Option value={role.uuid} key={index}>
                        {role.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <label className="title">
              Password<span style={{ color: "red" }}>*</span>
            </label>
            <Form.Item
              name={"password"}
              rules={[
                { required: true, message: "Please input your password!" },
                { min: 6, message: "Password must be 6 character long" },
              ]}
            >
              <Input.Password
                name="password"
                autoComplete="new-password"
                placeholder="Password..."
              />
            </Form.Item>
            <Row gutter={"12"}>
              <Col xs={24} sm={12}>
                <Form.Item name="is_active" valuePropName="checked">
                  <Checkbox checked={true}>Is active?</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Submit
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateUserModal;
