import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Loader/index";
import { Row, Col, Card, Typography, Collapse, Tabs, Modal, Space, Avatar, Tooltip } from "antd";
import { TaskContext } from "../../../context/TaskContext";
import { getFormattedDateTime } from "../../../utils";



const Task = () => {

    const { TabPane } = Tabs;
    const { uuid } = useParams();
    const { Title, Paragraph } = Typography;
    const {getSingleTask , data , singleTaskLoader} = useContext(TaskContext);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        console.log(data);
        getSingleTask(uuid);
    }, []);

    if (singleTaskLoader) {
        return <Loader />;
    }else{
        const tab1Sec = () => (
            <>
              <Row gutter={[6, 0]}>
                <Col xs={24} sm={24} md={12} lg={18} xl={18}>
                  <div className="profile-details">
                    <Row gutter={[24, 0]}>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <h5 className="detail-title">Title</h5>
                        <p className="detail-description">
                          {data?.title}
                        </p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <h5 className="detail-title">Project</h5>
                        <p className="detail-description">
                          {data?.project?.title}
                        </p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <h5 className="detail-title">Priority</h5>
                        <p className="detail-description">
                          {data?.priority?.name}
                        </p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <h5 className="detail-title">Status</h5>
                        <p className="detail-description">{data?.status?.name}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <h5 className="detail-title">Deadline</h5>
                        <p className="detail-description">{getFormattedDateTime(data?.deadline)}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <h5 className="detail-title">Is Billable</h5>
                        <p className="detail-description">{data?.is_billable == 1 ? 'Yes' : 'No'}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <h5 className="detail-title">Cost</h5>
                        <p className="detail-description">{data?.cost}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <h5 className="detail-title">Billing Type</h5>
                        <p className="detail-description">{data?.billing_type?.name}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <h5 className="detail-title">Hours</h5>
                        <p className="detail-description">{data?.hours}</p>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <h5 className="detail-title">Created At</h5>
                        <p className="detail-description">{getFormattedDateTime(data?.created_at)}</p>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <h5 className="detail-title">Updated At</h5>
                        <p className="detail-description">{getFormattedDateTime(data?.updated_at)}</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              {modalVisible && (
                <div className="layout-content">
                  <div className="mb-24">
                    <Card bordered={false} className="criclebox">
                      {/* Rest of the code */}
                    </Card>
                  </div>
      
                  <Modal
                    visible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    footer={null}
                  >
                    <img
                      src={largeImageUrl}
                      alt="Large Avatar"
                      style={{ width: "100%" }}
                    />
                  </Modal>
                </div>
              )}
            </>
          );

          const tab2Sec = () => (
            <>
              <Row gutter={[6, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p
                    className="detail-description"
                    style={{
                      whiteSpace: "pre-wrap",
                      fontFamily: "Arial",
                      fontSize: "14px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  >
                    {data?.description}
                  </p>
                </Col>
              </Row>
            </>
          );
      
          const noData = (message) => <p className="no-data">{message}</p>;

          return (
            <div className="layout-content">
              <div className="mb-24">
                <Card bordered={false} className="criclebox">
                  <Title level={5}>{data?.headline}</Title>
                  <Paragraph className="lastweek" style={{ marginBottom: 5 }}>
                    {data?.brief}
                  </Paragraph>
                  <Tabs defaultActiveKey="1" style={{ marginTop: 20 }}>
                    <TabPane tab="Detail" key="1">
                      {tab1Sec()}
                    </TabPane>
                    <TabPane tab="Description" key="2">
                      {tab2Sec()}
                    </TabPane>

                  </Tabs>
                </Card>
              </div>
            </div>
          );
    }

}

export default Task;