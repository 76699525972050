import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Loader/index";
import AdminDataTable from "../../../components/Layouts/sharedAdminLayout/partials/data-table";
import "../../Modals/styles.scss";
import "./style.scss";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Form,
  Row,
  Col,
  Card,
  Typography,
  Collapse,
  Tabs,
  Modal,
  Space,
  Avatar,
  Tooltip,
  Select,
} from "antd";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import { getFormattedDate, getFormattedDateTime } from "../../../utils";
import { SubscriptionItemContext } from "../../../context/SubscriptionItemContext";
import { ProductContext } from "../../../context/ProductContext";
import { ConfirmationModal } from "../../../components/Modals";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Subscription = () => {
  const { TabPane } = Tabs;
  const { uuid } = useParams();
  const { Title, Paragraph } = Typography;
  const {
    getSingleSubscription,
    data,
    singleSubscriptionLoaders,
    subscriptionItems,
  } = useContext(SubscriptionContext);
  const {
    getAllSubscriptionItemList,
    allSubscriptionItemList,
    registerSubscriptionItem,
    deleteSubscriptionItem,
    loading,
  } = useContext(SubscriptionItemContext);
  const [addSubscriptionItem, setAddSubscriptionItem] = useState(false);
  const { getProductList, productList } = useContext(ProductContext);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [clientRole, setClientRole] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    setClientRole(localStorage.getItem("crm_role"));
  }, []);

  useEffect(() => {
    console.log(data);
    getSingleSubscription(uuid);
    getAllSubscriptionItemList(1, true);
    getProductList();
  }, []);

  const handleAddItem = () => {
    setAddSubscriptionItem(true);
  };

  const handleCancelItem = () => {
    setAddSubscriptionItem(false);
  };

  const handleFormSubmit = () => {
    const data = form.getFieldsValue();
    let tempData = {
      product_uuid: data.product_uuid,
      subscription_uuid: uuid,
    };

    registerSubscriptionItem(tempData);
    getSingleSubscription(uuid);
    setAddSubscriptionItem(false);

    form.resetFields();
  };

  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteData = (id) => {
    deleteSubscriptionItem(id);
    setTimeout(() => {
      getSingleSubscription(uuid);
    }, 400);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (subscriptionItems, value, i) => <b>{i + 1}</b>,
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      render: (subscriptionItems, value) => {
        return (
          <span>
            <Link
              to={`../product/${value?.product_uuid}`}
              className={"subscription_items_link"}
            >
              {value?.product?.name}
            </Link>
          </span>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
  ];

  if (
    clientRole == "05857c29-5129-4aed-ae5e-30f0155fdbf3" ||
    clientRole == "33e2bba8-73ef-44f0-a6ab-60ff5146b74f"
  ) {
    columns.push({
      title: "Action",
      key: "action",
      render: (text, record) => {
        const { slug, uuid } = record;
        return (
          <div className="actions">
            <span>
              <Tooltip title="Delete Subscription Item">
                <DeleteOutlined onClick={() => handleConfirmModal(record)} />
              </Tooltip>
            </span>
          </div>
        );
      },
    });
  }

  if (singleSubscriptionLoaders) {
    return <Loader />;
  } else {
    const tab1Sec = () => (
      <>
        <Row gutter={[6, 0]}>
          <Col xs={24} sm={24} md={12} lg={18} xl={18}>
            <div className="profile-details">
              <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Client</h5>
                  <p className="detail-description">
                    {data?.client?.user?.fullname}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Billing Frequency</h5>
                  <p className="detail-description">
                    {data?.billing_frequency?.name}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Subscription Type</h5>
                  <p className="detail-description">
                    {data?.subscription_type?.name}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Status</h5>
                  <p className="detail-description">{data?.status?.name}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Start Date</h5>
                  <p className="detail-description">
                    {getFormattedDateTime(data?.start_date)}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Created At</h5>
                  <p className="detail-description">
                    {getFormattedDateTime(data?.created_at)}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <h5 className="detail-title">Updated At</h5>
                  <p className="detail-description">
                    {getFormattedDateTime(data?.updated_at)}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </>
    );

    const tab2Sec = () => (
      <>
        {addSubscriptionItem && (
          <Form form={form} onFinish={handleFormSubmit}>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} className="mb-24">
                <h2
                  style={{
                    fontSize: 17,
                    textAlign: "left",
                    marginTop: "30px",
                  }}
                >
                  Add Subscription Item
                </h2>

                <Form.Item
                  name={"product_uuid"}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Product!",
                    },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="product_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {productList?.map((product, index) => (
                      <Option value={product.uuid} key={index}>
                        {product.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} className="mb-24">
                <div className="add-btn">
                  <button onClick={handleCancelItem} className="close">
                    Cancel
                  </button>
                </div>

                <div className="add-btn">
                  <button type="submit">Save</button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            {clientRole == "05857c29-5129-4aed-ae5e-30f0155fdbf3" ||
            clientRole == "33e2bba8-73ef-44f0-a6ab-60ff5146b74f" ? (
              <>
                {!addSubscriptionItem && (
                  <div className="add-btn">
                    <button onClick={handleAddItem}>Add Item</button>
                  </div>
                )}
              </>
            ) : (
              ""
            )}
            <AdminDataTable data={subscriptionItems} columns={columns} />
          </Col>
        </Row>

        {showConfirmationModal && (
          <ConfirmationModal
            handleClose={handleConfirmationModalClose}
            title={"Delete Subscription Item?"}
            description={
              "Are you sure you want to delete this subscription item?"
            }
            deleteHandler={handleDeleteData}
            selectedData={selectedData}
          />
        )}
      </>
    );
    const noData = (message) => <p className="no-data">{message}</p>;

    return (
      <div className="layout-content">
        <div className="mb-24">
          <Card bordered={false} className="criclebox">
            <Title level={5}>{data?.headline}</Title>
            <Paragraph className="lastweek" style={{ marginBottom: 5 }}>
              {data?.brief}
            </Paragraph>
            <Tabs defaultActiveKey="1" style={{ marginTop: 20 }}>
              <TabPane tab="Detail" key="1">
                {tab1Sec()}
              </TabPane>
              <TabPane tab="Items" key="2">
                {tab2Sec()}
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    );
  }
};

export default Subscription;
