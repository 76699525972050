import React, { createContext, useState } from "react";
import axios from "axios";
import { BEARER_TOKEN_HEADERS, errorToast } from "../utils";

export const StatusTypeContext = createContext();

export const StatusTypeContextProvider = ({ children }) => {

    const [statusTypeList, setStatusTypeList] = useState([]);

    const getStatusTypeList = async () => {
        try {
            const res = await axios.get(
              `${process.env.REACT_APP_BACKEND_ENV}/status_types`,
              BEARER_TOKEN_HEADERS
            );
            setStatusTypeList(res?.data);
          } catch (err) {
            errorToast(err);
          }
    }

    return (
        <StatusTypeContext.Provider
        value={{
            getStatusTypeList,
            statusTypeList

        }}
        >
        {children}
        </StatusTypeContext.Provider>
    )

}