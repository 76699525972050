import { Form, Modal, Input, Select, Checkbox, Col, Row } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { PriorityContext } from "../../../context/PriorityContext";
import { BillingTypeContext } from "../../../context/BillingTypeContext";
import { StatusContext } from "../../../context/StatusContext";
import { ClientContext } from "../../../context/ClientContext";
import { CompanyContext } from "../../../context/CompanyContext";
import TextArea from "antd/lib/input/TextArea";
import ModalDateFormat from "../../ModalDateFormatter";

const UpdateProjectModal = ({ handleClose, selectedData, updateData }) => {
  const { getPriorityList, priorityList } = useContext(PriorityContext);
  const { getBillingTypeList, billingtypeList } =
    useContext(BillingTypeContext);
  const { getStatusList, statusList, loading } = useContext(StatusContext);
  const { getAllClientList, allUsersList } = useContext(ClientContext);
  const { getAllCompanyList, allCompanyList } = useContext(CompanyContext);
  const [companyUuid] = useState(localStorage.getItem("crm_company_uuid"));

  const [form] = Form.useForm();

  useEffect(() => {
    getPriorityList();
    getBillingTypeList();
    getStatusList("?type=project");

    let companyQuery = "";

    if (companyUuid) {
      companyQuery = `/companies/${companyUuid}`;
    }

    getAllClientList(companyQuery);
    getAllCompanyList();
    console.log(selectedData.uuid);
  }, []);

  useEffect(() => {
    const formatedStartDate = ModalDateFormat(selectedData.start_date);
    const formatedEndDate = ModalDateFormat(selectedData.end_date);
    form.setFieldsValue({
      title: selectedData.title,
      priority_uuid: selectedData.priority_uuid,
      billing_type_uuid: selectedData.billing_type_uuid,
      client_uuid: selectedData.client_uuid,
      company_uuid: selectedData.company_uuid,
      status_uuid: selectedData.status_uuid,
      start_date: formatedStartDate,
      end_date: formatedEndDate,
      budget: selectedData.budget,
      description: selectedData.description,
    });
  }, []);

  const handleFormSubmit = () => {
    const data = form.getFieldsValue();

    let formData = new FormData();

    formData.append("title", data.title);
    formData.append("priority_uuid", data.priority_uuid);
    formData.append("billing_type_uuid", data.billing_type_uuid);
    formData.append("client_uuid", data.client_uuid);
    formData.append("status_uuid", data.status_uuid);
    formData.append("start_date", data.start_date);
    formData.append("end_date", data.end_date);
    formData.append("budget", data.budget);
    formData.append("description", data.description);

    if (companyUuid) {
      formData.append("company_uuid", companyUuid);
    } else {
      formData.append("company_uuid", data.company_uuid);
    }

    console.log(formData);

    updateData(formData, selectedData.uuid);
    handleClose();
  };

  return (
    <Modal
      visible={true}
      title={<span>Update Project</span>}
      centered
      onCancel={handleClose}
      footer={null}
      width={750}
      className="modal"
    >
      <div className="modal-wrapper">
        <Form form={form} onFinish={handleFormSubmit}>
          <div className="modal-body">
            <Row gutter={"12"}>
              <Col xs={24} sm={8}>
                <label className="title">
                  Title<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please Input your Title!",
                    },
                  ]}
                >
                  <Input name="title" type="text" placeholder="Title..." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <label className="title">
                  Priority <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"priority_uuid"}
                  rules={[
                    { required: true, message: "Please select your Priority!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="priority_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {priorityList?.map((priority, index) => (
                      <Option value={priority.uuid} key={index}>
                        {priority.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <label className="title">
                  Billing Type <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"billing_type_uuid"}
                  rules={[
                    {
                      required: true,
                      message: "Please select your Billing Type!",
                    },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="billing_type_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {billingtypeList?.map((billing_type, index) => (
                      <Option value={billing_type.uuid} key={index}>
                        {billing_type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <label className="title">
                  Client <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"client_uuid"}
                  rules={[
                    { required: true, message: "Please select your Client!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="client_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {allUsersList?.map((clients, index) => (
                      <Option value={clients.uuid} key={index}>
                        {clients?.user?.firstname} {clients?.user?.lastname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {!companyUuid && (
                <Col xs={24} sm={8}>
                  <label className="title">
                    Company <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Item
                    name={"company_uuid"}
                    rules={[
                      {
                        required: true,
                        message: "Please select your Company!",
                      },
                    ]}
                  >
                    <Select
                      placeholder=""
                      name="company_uuid"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {allCompanyList?.map((company, index) => (
                        <Option value={company.uuid} key={index}>
                          {company?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              <Col xs={24} sm={8}>
                <label className="title">
                  Status <span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item
                  name={"status_uuid"}
                  rules={[
                    { required: true, message: "Please select your Status!" },
                  ]}
                >
                  <Select
                    placeholder=""
                    name="status_uuid"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {statusList?.map((status, index) => (
                      <Option value={status.uuid} key={index}>
                        {status?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={8}>
                <label className="title">
                  Start Date<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item name="start_date">
                  <Input name="start_date" type="date" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={companyUuid ? 12 : 8}>
                <label className="title">
                  End Date<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item name="end_date">
                  <Input name="end_date" type="date" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={companyUuid ? 12 : 8}>
                <label className="title">
                  Budget<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item name="budget">
                  <Input name="budget" type="number" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <label className="title">
                  Description<span style={{ color: "red" }}>*</span>
                </label>
                <Form.Item name="description">
                  <TextArea
                    name="description"
                    type="text"
                    placeholder="Description..."
                    rows={4}
                  ></TextArea>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="close" onClick={handleClose}>
              Close
            </button>
            <button className="submit" type="submit">
              Update
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UpdateProjectModal;
